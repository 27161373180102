import {
  Browser,
  BrowserType,
  CustomData,
  Device,
  DeviceType,
  useData,
  useFeatureFlag,
  useInitialize,
  useVisitorCode
} from '@kameleoon/react-sdk'
import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/react'
import { useCallback, useEffect, useState } from 'react'
import { browserName, isMobile, isTablet } from 'react-device-detect'
import { useKameleoonStore } from 'stores/kameleoonStore'
import { usePatientDataStore } from '../../stores/patientData'
import { reportToSentry } from '../../utils/reportToSentry'
import { CUSTOM_DATA } from 'utils/kameleoon/constants'

function getBrowserType() {
  // Check for common browsers
  if ( browserName in BrowserType ) {
    return BrowserType[browserName as keyof typeof BrowserType]
  } else {
    return BrowserType.Other
  }
}

export default function KameleoonInit() {
  const [ engineCode, setEngineCode ] = useState<string>( `` )
  const { initialize } = useInitialize()
  const { getVisitorCode } = useVisitorCode()
  const { addData } = useData()
  const { setVisitorCode, setUxUpdateFeature } = useKameleoonStore()
  const { isFeatureFlagActive, getEngineTrackingCode } = useFeatureFlag()
  const customerPricingData = usePatientDataStore( store => store.customerPricingData )
  // Waiting for the client initialization using `async/await`
  const init = useCallback( async (): Promise<void> => {
    await initialize()
    // Get visitor code
    const visitorCode = getVisitorCode()
    const browserData = new Browser( getBrowserType() )
    const deviceData = new Device( isMobile ? DeviceType.Phone : isTablet ? DeviceType.Tablet : DeviceType.Desktop )
    addData( visitorCode, browserData, deviceData )
    setVisitorCode( visitorCode )
    // Store scheduling_app_ux_updates feature flag
    setUxUpdateFeature( isFeatureFlagActive({
      visitorCode,
      featureKey: `scheduling_ux_updates`
    }) )
    setEngineCode( getEngineTrackingCode( visitorCode ) )
    // if patient data is available, add it to Kameleoon
    if ( customerPricingData?.patient?.patient_id ) {
      const customData = new CustomData( CUSTOM_DATA.PATIENT_ID, customerPricingData.patient.patient_id.toString() )
      addData( visitorCode, customData )
    }
    let {origin} = window.location
    if ( window.location !== window.parent.location && process.env.REACT_APP_MAGENTO_2_BASE_URL && document.location.ancestorOrigins.contains( process.env.REACT_APP_MAGENTO_2_BASE_URL ) ) {
      /* The window and parent window are not the same when the lactation scheduler is rendered in an iframe, so if
      our Magento url is in the iframe's ancestorOrigins, we should use that as the origin url to send to Kameleoon */
      origin = process.env.REACT_APP_MAGENTO_2_BASE_URL
    }
    const parentWindowUrlData = new CustomData( CUSTOM_DATA.ORIGIN_URL, origin )
    addData( visitorCode, parentWindowUrlData )
  }, [ initialize, getVisitorCode, isFeatureFlagActive, getEngineTrackingCode, customerPricingData ] )

  useEffect( () => {
    const handleKameleoonLoaded = () => {
      Sentry.addBreadcrumb({
        'type': `default`,
        'category': `kameleoon`,
        'data': undefined,
        'level': Severity.Info,
        'message': `Kameleoon Loaded`,
        'timestamp': Date.now()
      })
    }
    window.addEventListener( `Kameleoon::Loaded`, handleKameleoonLoaded )

    init().catch( ( error ) => {
      // there was an issue with the initialization of kameleoon
      reportToSentry( `Kameleoon initialization failed`, error )
    })

    if ( engineCode ) {
      const script = document.createElement( `script` )
      script.textContent = engineCode
      document.body.appendChild( script )

      return () => {
        document.body.removeChild( script )
        window.removeEventListener( `Kameleoon::Loaded`, handleKameleoonLoaded )
      }
    }

    return () => {
      window.removeEventListener( `Kameleoon::Loaded`, handleKameleoonLoaded )
    }
  }, [ init ] )

  return null
}
