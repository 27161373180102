import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SCHEDULE_BUNDLE_BASE_PATH } from 'views/ScheduleBundle/utils/constants'
import BundleCard from './BundleCard'
import { AppointmentsLoadingBox } from 'components/AppointmentsLoadingBox'
import { SchedulingErrorBlock } from 'components/SchedulingErrorBlock'
import useFetchBundles from 'hooks/useFetchBundles'
import useSelectedClasses from 'hooks/useSelectedClasses'
import useSelectedBundle from 'hooks/useSelectedBundle'
import useClassScheduler from 'stores/useClassSchedulerStore'
import { Bundle } from 'types/shopScheduleTypes'
import { INDIVIDUAL_SELECTION_BASE_PATH } from 'routes'
import { IndividualClassSrc } from 'assets'

const IndivBundle: Bundle = {
  bundle_sku: `individual`,
  bundle_title: `Build Your Own Bundle`,
  bundle_description: `Not seeing exactly what you want? Create your own bundle!`,
  classes: [],
  image_path: IndividualClassSrc,
  reviews: 0,
  number_of_reviews: 0,
  price: ``,
  bundle_short_description: ``,
  unclaimed_bundle: false
}

export default function ShopScheduleMain(): JSX.Element {
  const { selectedBundle, setSelectedBundle, clearBundle } = useSelectedBundle()
  const { clearClasses } = useSelectedClasses()
  const { bundles, error } = useFetchBundles()
  const resetScheduler = useClassScheduler( state => state.resetScheduler )

  const navigate = useNavigate()

  useEffect( () => {
    sessionStorage.removeItem( `has_scheduled` )
  }, [] )


  const toScheduleBundlePage = () => {
    if ( selectedBundle ) {
      if ( selectedBundle.bundle_sku === IndivBundle.bundle_sku ) {
        navigate( INDIVIDUAL_SELECTION_BASE_PATH.concat( window.location.search ) )
      } else {
        clearClasses()
        navigate( SCHEDULE_BUNDLE_BASE_PATH.concat( window.location.search ) )
      }
    }
  }

  const selectBundle = ( bundle: Bundle ) => {
    resetScheduler()
    if ( selectedBundle?.bundle_sku === bundle.bundle_sku ) {
      clearBundle()
    } else {
      setSelectedBundle( bundle )
    }
  }

  if ( error ) return <SchedulingErrorBlock message={error} />

  if ( !bundles ) return <AppointmentsLoadingBox message={`Please hold tight while we grab your available bundles...`} />

  return (
    <div className="flex justify-center items-center flex-col px-5 text-center gap-5">
      <h2 className="text-3xl md:text-4xl font-header">{`Choose Your Class Bundle`}</h2>
      <p className="text-sm md:text-xl">{`All classes are online and taught live by certified instructors!`}</p>
      <div className="p-5 pb-24 flex flex-col gap-10 w-full max-w-2xl">
        {
          bundles?.length > 0 &&
          <>
            {
              bundles.filter( ( bundle: Bundle ) => {
                return Boolean( bundle.bundle_sku )
              }).map( ( bundle: Bundle ) => {
                return (
                  <BundleCard
                    key={bundle.bundle_sku}
                    bundleDetails={bundle}
                    selectBundle={() => {
                      return selectBundle( bundle )
                    }}
                    selected={selectedBundle?.bundle_sku === bundle.bundle_sku}
                  />
                )
              })
            }
            <BundleCard
              key={IndivBundle.bundle_sku}
              bundleDetails={IndivBundle}
              selectBundle={() => {
                return selectBundle( IndivBundle )
              }}
              selected={selectedBundle?.bundle_sku === IndivBundle.bundle_sku}
            />
          </>
        }
      </div>
      {selectedBundle && (
        <div className="fixed bottom-0 p-5 bg-white-pink w-full z-above-chat">
          <div className="flex gap-6 justify-between items-center max-w-2xl mx-auto">
            <div>
              <p className="text-sm font-semibold">{`Selected: ${selectedBundle.bundle_title}`}</p>
            </div>
            <button
              onClick={toScheduleBundlePage}
              className="primary-button"
            >
              {`Next`}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}