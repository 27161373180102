import React from 'react'
import { Modal } from 'components/modal'
import { Xicon } from 'assets'
import { Class } from 'types/shopScheduleTypes'
import { ReviewDisplay } from 'components/ReviewDisplay'
import ReactHtmlParser from 'react-html-parser'

type AdditionalDetailsProps = {
  classDetails: Class | null
  handleClose: () => void
  addClass: () => void
}

function ClassAdditionalDetails({
  classDetails,
  handleClose,
  addClass
}: AdditionalDetailsProps ): JSX.Element {
  return (
    <Modal
      isOpen={Boolean( classDetails )}
      onClose={handleClose}
      defaultCloseButton={false}
    >
      <div className="flex flex-col items-center justify-center gap-2 px-8 md:px-12 pt-5 pb-10">
        <div
          className="absolute top-1 right-1 cursor-pointer hover:opacity-80 p-2"
          onClick={handleClose}
        >
          <img
            src={Xicon}
            alt="close modal icon"
            width={15}
            height={15}
          />
        </div>
        <p className="text-3xl font-header mb-5 mt-6">
          {classDetails?.class_title}
        </p>
        <div className="shadow-sm mb-5 w-full">
          <img
            src={classDetails?.image_path}
            alt="class image"
            className="w-full object-contain bg-gray-white"
          />
        </div>
        {classDetails?.reviews && classDetails?.number_of_reviews && (
          <ReviewDisplay
            reviews={classDetails?.reviews}
            number_of_reviews={classDetails?.number_of_reviews}
            vertical={false}
          />
        )}
        <p className="uppercase text-2xl md:text-3xl text-secondary font-semibold mt-3">
          {classDetails?.class_price}
        </p>
        <p className="text-lg md:text-xl mt-5 font-semibold">{`In this class you will learn:`}</p>
        <div className="mt-5 text-base md:text-lg">
          {classDetails &&
            classDetails?.class_description?.length > 0 &&
            ReactHtmlParser( classDetails.class_description )}
        </div>
        <button
          className="primary-button my-5"
          onClick={() => {
            addClass()
          }}
        >
          {`ADD CLASS`}
        </button>
        <p
          className="a"
          onClick={handleClose}
        >{`Go back`}</p>
      </div>
    </Modal>
  )
}

export default ClassAdditionalDetails
