import { TimeSlot } from 'types/shopScheduleTypes'
import { create } from 'zustand'

export const TIME_FILTERS = {
  morning: `Morning Only`,
  evening: `Evening Only`,
  weekend: `Weekends Only`
}

type ClassFiltersState = {
  topics: Set<string>;
  filteredTopics: string[];
  timeFilter: string;
  setTopics: ( _topics: Set<string> ) => void;
  addFilteredTopic: ( _classFilter: string ) => void;
  removeFilteredTopic: ( _classFilter: string ) => void;
  clearFilteredTopic: () => void;
  setTimeFilter: ( _timeFilter: string ) => void;
  clearTimeFilter: () => void;
  matchesTimeFilter: ( _classDetails: TimeSlot, _timezone: string ) => boolean;
  resetFilters: () => void;
}

const useClassFiltersStore = create<ClassFiltersState>( ( set, get ) => {
  return {
    topics: new Set(),
    filteredTopics: [],
    timeFilter: ``,
    setTopics: ( topics: Set<string> ) => {
      set({
        topics
      })
    },
    addFilteredTopic: ( classFilter: string ) => {
      set( state => {
        return {
          filteredTopics: state.filteredTopics.concat( classFilter )
        }
      })
    },
    removeFilteredTopic: ( classFilter: string ) => {
      set( state => {
        return {
          filteredTopics: state.filteredTopics.filter( ( filter ) => {
            return filter !== classFilter
          })
        }
      })
    },
    clearFilteredTopic: () => {
      set({
        filteredTopics: []
      })
    },
    setTimeFilter: ( timeFilter: string ) => {
      set({
        timeFilter
      })
    },
    clearTimeFilter: () => {
      set({
        timeFilter: ``
      })
    },
    matchesTimeFilter: ( timeSlot: TimeSlot, timezone: string ) => {
      const dateString = new Date( timeSlot.timeslot_exact_timestamp ).toLocaleDateString( `en-US`, {
        timeZone: timezone,
        hour: `numeric`,
        minute: `numeric`,
        second: `numeric`
      })
      const timeSlotDate = new Date( dateString )
      const hours = timeSlotDate.getHours()

      switch ( get().timeFilter ) {
      case TIME_FILTERS.morning:
        return hours < 12
      case TIME_FILTERS.evening:
        return hours >= 12
      case TIME_FILTERS.weekend:
        return [ 0, 6 ].includes( timeSlotDate.getDay() )
      default:
        return true
      }
    },
    resetFilters: () => {
      set({
        topics: new Set(),
        filteredTopics: [],
        timeFilter: ``
      })
    }
  }
})

export default useClassFiltersStore