import React, { ReactElement } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { ThemeProvider as Emotion10ThemeProvider } from '@emotion/react'
import tailwindColors from './tailwindColors'

const theme = createTheme({
  palette: {
    primary: {
      main: tailwindColors.primary,
      contrastText: tailwindColors.white.DEFAULT
    },
    secondary: {
      main: tailwindColors.secondary,
      contrastText: tailwindColors.white.DEFAULT
    }
  }
})

function MABTheme({ children } : { children: ReactElement }) : JSX.Element {
  return (
    <Emotion10ThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </Emotion10ThemeProvider>
  )
}

export default MABTheme