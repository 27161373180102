import { LeftCarat, RightCarat } from "assets"

type SchedulerPaginationProps = {
    page: number;
    totalPages: number;
    setPage: ( _page: number ) => void;
}
const SchedulerPagination = ({ page, setPage, totalPages }: SchedulerPaginationProps ) => {

  return <div className="flex justify-center gap-4">
    <button
      className={`p-3 ${page <= 1 ? `cursor-not-allowed opacity-50` : ``}`}
      disabled={page <= 1}
      onClick={() => setPage( page - 1 )}
    >
      <img
        src={LeftCarat}
        alt="previous page"
        height={14}
        width={14}
      />
    </button>
    <button
      className={`p-3 ${page >= totalPages ? `cursor-not-allowed opacity-50` : ``}`}
      disabled={page >= totalPages}
      onClick={() => setPage( page + 1 )}
    >
      <img
        src={RightCarat}
        alt="next page"
        height={14}
        width={14}
      />
    </button>
  </div>
}
export default SchedulerPagination