import { useEffect, useMemo, useRef, useState } from 'react'
import { Class, ClassDescription, TimeSlot } from 'types/shopScheduleTypes'
import { TIME_ZONES } from 'utils/constants'
import DatePickerModal from './DatePickerModal'
import { dateFormatter, timeSlotFormatter } from 'utils/time'
import { ThemedModal } from 'components/ThemedModal'
import useClassFilters from 'stores/useClassFiltersStore'
import useClassScheduler from 'stores/useClassSchedulerStore'
import getClassDescription from 'apis/getClassDescription'
import useAuthStore from 'stores/auth'
import SchedulerPagination from './SchedulerPagination'
import SchedulerTimeSlot from './SchedulerTimeSlot'

type SchedulerProps = {
  classDetails: Class
}

const Scheduler = ({ classDetails }: SchedulerProps ): JSX.Element => {
  const schedulerRef = useRef<HTMLDivElement>( null )
  const [ loading, setLoading ] = useState<boolean>( true )
  const [ error, setError ] = useState<boolean>( false )

  const availableClasses = useClassScheduler( state => state.availableClasses )
  const selectedClassPks = useClassScheduler( state => state.selectedClassPks )
  const selectedClasses = useClassScheduler( state => state.selectedClasses )
  const completedClasses = useClassScheduler( state => state.completedClasses )
  const openClass = useClassScheduler( state => state.openClass )
  const classLimit = useClassScheduler( state => state.classLimit )
  const previouslyScheduledClasses = useClassScheduler( state => state.previouslyScheduledClasses )
  const timezone = useClassScheduler( state => state.timezone )
  const rescheduling = useClassScheduler( state => state.rescheduling )
  const removeSeriesClass = useClassScheduler( state => state.removeSeriesClass )
  const addAvailableClass = useClassScheduler( state => state.addAvailableClass )
  const removeClassSelection = useClassScheduler( state => state.removeClassSelection )
  const setOpenClass = useClassScheduler( state => state.setOpenClass )
  const setTimeZone = useClassScheduler( state => state.setTimeZone )
  const setRemoveSeriesClass = useClassScheduler( state => state.setRemoveSeriesClass )

  const { buildAuthorizer } = useAuthStore()
  const authHeader = buildAuthorizer()

  const classDescription = availableClasses.find( ( _class: ClassDescription ) => _class.class_id === classDetails.class_id )
  const selectedTimeSlot = classDescription?.timeslots.find( ( timeSlot: TimeSlot ) => selectedClassPks.includes( timeSlot.class_pk ) )
  const previouslyScheduledTimeSlot = previouslyScheduledClasses.find( scheduledClass => scheduledClass.class_id === classDetails.class_id )?.start_time_utc

  const timeFilter = useClassFilters( state => state.timeFilter )
  const matchesTimeFilter = useClassFilters( state => state.matchesTimeFilter )

  const [ scheduling, setScheduling ] = useState<boolean>( openClass === classDetails.class_id )
  const [ selectedClassPk, setSelectedClassPk ] = useState<number | undefined>( selectedTimeSlot?.class_pk )
  const [ selectedDate, setSelectedDate ] = useState<Date>()
  const [ filterByDate, setFilterByDate ] = useState<boolean>( false )

  const [ modalMessage, setModalMessage ] = useState<string>( `Your maximum course availability is ${classLimit} classes at this time` )
  const [ openModal, setOpenModal ] = useState<boolean>( false )
  const closeModal = () => {
    setModalMessage( `Your maximum course availability is ${classLimit} classes at this time` )
    setOpenModal( false )
  }

  const getFilteredClasses = (): TimeSlot[] => {
    let reschedulingPk: number | undefined
    const previouslyScheduledDates = new Set(
      previouslyScheduledClasses
        .filter( scheduledClass => {
          if ( rescheduling && scheduledClass.class_id === classDetails.class_id ) {
            reschedulingPk = scheduledClass.class_pk

            return false
          }

          return true
        })
        .map( scheduledClass => scheduledClass.scheduled_date_pretty )
    )
    const classes = classDescription?.timeslots.filter( timeSlot => {
      return !previouslyScheduledDates.has( timeSlot.scheduled_date_pretty ) && timeSlot.class_pk !== reschedulingPk
    }) ?? []
    const filteredByTime = classes.filter( ( timeSlot: TimeSlot ) => {
      if ( timeSlot.class_pk === selectedClassPk ) return true

      return matchesTimeFilter( timeSlot, timezone )
    })
    if ( !filterByDate ) return filteredByTime

    return filteredByTime.filter( ( timeSlot: TimeSlot ) => {
      const date = new Date( timeSlot.scheduled_date )

      return date.getUTCFullYear() === selectedDate?.getUTCFullYear() && date.getUTCMonth() === selectedDate?.getUTCMonth() && date.getUTCDate() === selectedDate?.getUTCDate()
    })
  }

  const filteredClasses = useMemo( getFilteredClasses, [ classDescription, filterByDate, timeFilter, timezone, selectedClassPk ] )

  const [ page, setPage ] = useState<number>( 1 )
  const totalPages = Math.ceil( filteredClasses.length / 5 )

  const getSeriesClasses = ( _classSeriesPk: number ): ClassDescription[] => {
    return availableClasses.filter( ( availableClass: ClassDescription ) => {
      return availableClass.class_series_pk === _classSeriesPk
    }).filter( ( availableSeriesClass: ClassDescription ) => {
      return completedClasses?.find( ( completedClass ) => {
        return completedClass.class_id === availableSeriesClass.class_id
      }) === undefined
    })
      .sort( ( a: ClassDescription, b: ClassDescription ) => {
        return a.class_sequence - b.class_sequence
      })
  }

  const reschedulingSeries = (): boolean => {
    return rescheduling && availableClasses.length > 1
  }

  const scheduleClass = () => {
    if ( classDetails.is_series && classDetails.class_series_pk ) {
      const seriesClasses = getSeriesClasses( classDetails.class_series_pk )
      const currentClassIndex = seriesClasses.findIndex( ( seriesClass: ClassDescription ) => {
        return seriesClass.class_id === classDetails.class_id
      })
      let nextClassInSeries = ``
      let nextClassInSeriesIndex = 0
      for ( let i = 0; i < seriesClasses.length; i++ ) {
        const selectedClassIds = selectedClasses.map( ( selectedClass: Class ) => {
          return selectedClass.class_id
        })

        if ( !selectedClassIds.includes( seriesClasses[i].class_id ) ) {
          nextClassInSeries = seriesClasses[i].class_id
          nextClassInSeriesIndex = i
          break
        }
      }

      if ( nextClassInSeries !== classDetails.class_id && !selectedClassPk ) {
        const message = `Hey Mama! This class is part of a ${seriesClasses.length}-class series. Please schedule Part ${nextClassInSeriesIndex + 1} before scheduling Part ${currentClassIndex + 1}.`
        setOpenClass( nextClassInSeries )
        setModalMessage( message )
        setOpenModal( true )

        return
      }
    }
    setScheduling( true )
  }

  const clearClassSelection = () => {
    setSelectedClassPk( undefined )
    removeClassSelection( classDetails, selectedTimeSlot )
  }

  const closeScheduler = () => {
    if ( classDetails.is_series && classDetails.class_series_pk && selectedClassPk ) {
      const seriesClasses = getSeriesClasses( classDetails.class_series_pk )
      const indexOfCurrentClass = seriesClasses.findIndex( ( _class: ClassDescription ) => {
        return _class.class_id === classDetails.class_id
      })
      if ( indexOfCurrentClass < seriesClasses.length - 1 && !selectedClasses.map( ( _class ) => _class.class_id ).includes( seriesClasses[indexOfCurrentClass + 1].class_id ) ) {
        setOpenClass( seriesClasses[indexOfCurrentClass + 1].class_id )
        setModalMessage( `Hey Mama! This class is part of a ${seriesClasses.length}-class series. Next, you will schedule Part ${indexOfCurrentClass + 2} to take after Part ${indexOfCurrentClass + 1}.` )
        setOpenModal( true )
      }
    }
    if ( reschedulingSeries() && selectedClassPk ) {
      const seriesClasses = availableClasses.sort( ( a: ClassDescription, b: ClassDescription ) => {
        return a.class_sequence - b.class_sequence
      })
      const indexOfCurrentClass = seriesClasses.findIndex( ( _availableClass: ClassDescription ) => {
        return _availableClass.class_id === classDetails.class_id
      })
      if ( indexOfCurrentClass < availableClasses.length - 1 ) {
        setOpenClass( availableClasses[indexOfCurrentClass + 1].class_id )
      }
    }
    setScheduling( false )
  }

  useEffect( () => {
    const fetchClassDescription = async () => {
      try {
        const response = await getClassDescription( classDetails.class_id, authHeader )
        if ( !response ) throw new Error( `Failed to fetch class description` )
        addAvailableClass( response.data )
      } catch ( e ) {
        setError( true )
      }
      setLoading( false )
    }
    if ( !classDescription ) {
      fetchClassDescription()
    } else {
      setLoading( false )
    }
  }, [ classDetails.class_id ] )

  useEffect( () => {
    if ( !loading && scheduling ) {
      schedulerRef.current?.scrollIntoView({
        behavior: openClass ? `auto` : `smooth`,
        block: `center`
      })
    }
  }, [ scheduling, loading ] )

  useEffect( () => {
    if ( openClass === classDetails.class_id && schedulerRef.current ) {
      if ( !scheduling ) {
        setScheduling( true )
      }
      setOpenClass( `` )
    }
  }, [ openClass ] )

  useEffect( () => {
    if ( selectedTimeSlot && removeSeriesClass && classDetails.class_series_pk === removeSeriesClass ) {
      setSelectedClassPk( undefined )
      removeClassSelection( classDetails, selectedTimeSlot )
      setRemoveSeriesClass( null )
    }
  }, [ removeSeriesClass ] )

  if ( error ) return <p
    className="text-error text-sm font-semibold"
  >{`No matching class times available - please change your filters to see available classes`}</p>

  if ( loading ) return <div className="text-left">
    <button
      className="bg-secondary rounded-button p-button text-pink-1 text-sm tracking-widest uppercase"
    >{`Loading...`}</button>
  </div>

  return (
    <div ref={schedulerRef} className="flex flex-col gap-4 text-left">
      {rescheduling && previouslyScheduledTimeSlot &&
        <p className="text-center">{
          selectedTimeSlot === undefined
            ? `You're currently scheduled for ${timeSlotFormatter( timezone ).format( new Date( previouslyScheduledTimeSlot ) )}`
            : `Reschedule for ${timeSlotFormatter( timezone ).format( new Date( selectedTimeSlot.timeslot_exact_timestamp ) )}`
        }</p>
      }
      {filteredClasses.length > 0 ? (
        scheduling ? (
          <div className="bg-white-pink p-4 flex flex-col gap-4">
            <div className="flex gap-5">
              <select
                className="bg-white text-sm h-8"
                value={timezone ?? ``}
                onChange={( e ) => {
                  setTimeZone( e.target.value )
                }}
              >
                {TIME_ZONES.map( ( timeZone ) => {
                  return (
                    <option key={timeZone.label} value={timeZone.name}>{`${timeZone.name} Time Zone`}</option>
                  )
                })}
              </select>
            </div>
            <div className="flex justify-between items-baseline">
              {filterByDate ? <p>{`Searching for ${dateFormatter.format( selectedDate )}`}</p> : <p>{`Select a Date`}</p>}
              {filteredClasses.length || filterByDate ?
                <DatePickerModal
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  filterByDate={filterByDate}
                  setFilterByDate={setFilterByDate}
                  upcomingTimeSlots={filteredClasses}
                  setPage={setPage}
                /> : null}
            </div>
            <div>
              {filteredClasses.length > 0 && <p className="text-sm">{`${filteredClasses.length} Classes Available`}</p>}
            </div>
            <div className="flex flex-col">
              <div>
                {filteredClasses.slice( ( page - 1 ) * 5, page * 5 ).map( ( timeSlot: TimeSlot ) => <SchedulerTimeSlot
                  key={timeSlot.class_pk}
                  timeSlot={timeSlot}
                  classDetails={classDetails}
                  selectedTimeSlot={selectedTimeSlot}
                  setSelectedClassPk={setSelectedClassPk}
                />
                )}
              </div>
              <SchedulerPagination page={page} totalPages={totalPages} setPage={setPage} />
            </div>
            {( !rescheduling || reschedulingSeries() ) &&
              <button
                className="outline-button self-center text-sm tracking-widest"
                onClick={closeScheduler}
              >
                {selectedClassPk ? `Save & Close` : `Close`}
              </button>
            }
          </div>
        ) : (
          <div className="flex gap-2">
            <button
              className={`${selectedTimeSlot === undefined ? `secondary-button` : `outline-button`} rounded-button p-button-important text-sm text-pretty tracking-widest uppercase w-fit`}
              onClick={scheduleClass}
            >
              {selectedTimeSlot === undefined ? `Schedule` : `${timeSlotFormatter( timezone ).format( new Date( selectedTimeSlot.timeslot_exact_timestamp ) )}`}
            </button>
            {selectedTimeSlot && <button onClick={clearClassSelection} className="p-0 underline text-sm">{`Clear`}</button>}
          </div>
        )
      ) : (
        <p
          className="text-error text-sm font-semibold"
        >{`No matching class times available - please change your filters to see available classes`}</p>
      )}
      <ThemedModal
        message={modalMessage}
        open={openModal}
        handleClose={closeModal}
      />
    </div>
  )
}

export default Scheduler