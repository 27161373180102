import { useEffect, useState } from 'react'
import useClassFilters from 'stores/useClassFiltersStore'
import { Class } from 'types/shopScheduleTypes'
import FilterMapping from 'types/FilterMapping'
import { DownCarat, UpCarat, Xicon } from 'assets'
import TopicFilter from './TopicFilter'
import TimeFilter from './TimeFilter'

type ClassFiltersProps = {
  classes: Class[] | null
  filterMapping: FilterMapping[]
  hideTopicFilters?: boolean
  bundle?: boolean
}

function ClassFilters({ classes, filterMapping, hideTopicFilters, bundle } : ClassFiltersProps ) : JSX.Element | null {
  const [ dropdownOpen, setDropdownOpen ] = useState<boolean>( false )
  const [ showTopicFilters, setShowTopicFilters ] = useState<boolean>( false )
  const [ showTimeFilters, setShowTimeFilters ] = useState<boolean>( false )

  const topics = useClassFilters( state => state.topics )
  const setTopics = useClassFilters( state => state.setTopics )
  const timeFilter = useClassFilters( state => state.timeFilter )
  const topicFilters = useClassFilters( state => state.filteredTopics )
  const removeFilteredTopic = useClassFilters( state => state.removeFilteredTopic )
  const clearFilteredTopics = useClassFilters( state => state.clearFilteredTopic )

  useEffect( () => {
    if ( classes?.length && !topics.size ) {
      clearFilteredTopics()
      const buildTags:Set<string> = new Set()
      classes.forEach( classDetails => {
        if ( classDetails?.category ) buildTags.add( classDetails.category )
      })

      setTopics( buildTags )
    }

  }, [ classes ] )

  const tagButtons = ( topics?.size ? [ ...Array.from( topics ) ].sort( ( a: string, b: string ) => { return a.localeCompare( b ) }) : [] ).map( tag => {
    const tagMeta = filterMapping.find( tagMeta => { return tagMeta.value === tag })

    const buttonText = ( tagMeta?.label ?? tag ).toLowerCase().split( ` ` )
      .map( ( c ) => c.charAt( 0 ).toUpperCase() + c.substring( 1 ) )
      .join( ` ` )

    return Object.assign({}, {
      key: tag,
      selected: topicFilters.includes( tag ),
      text: buttonText
    })
  })

  const toggleTopicFilters = () => {
    if ( dropdownOpen && showTimeFilters ) {
      setShowTimeFilters( false )
      setShowTopicFilters( true )

      return
    }
    setDropdownOpen( !dropdownOpen )
    setShowTopicFilters( !showTopicFilters )
  }

  const toggleTimeFilters = () => {
    if ( dropdownOpen && showTopicFilters ) {
      setShowTopicFilters( false )
      setShowTimeFilters( true )

      return
    }
    setDropdownOpen( !dropdownOpen )
    setShowTimeFilters( !showTimeFilters )
  }

  if ( !topicFilters ) return null

  return (
    <div className="flex flex-col w-full max-w-2xl">
      <div className="flex gap-x-5 gap-y-2 justify-between text-sm pb-2">
        {!hideTopicFilters &&
        <button
          name="topicFilter"
          onClick={toggleTopicFilters}
          className="border border-gray-light rounded-button py-1.5 px-3.5 flex justify-between items-center w-full"
        >
          {`Filter by Topic`}
          {showTopicFilters ?
            <img
              src={UpCarat}
              height={6}
              width={12}
            />
            :
            <img
              src={DownCarat}
              height={6}
              width={12}
            />}
        </button>
        }
        <button
          name="timeFilter"
          onClick={toggleTimeFilters}
          className="border border-gray-light rounded-button py-1.5 px-3.5 flex justify-between items-center w-full"
        >
          {timeFilter.length ? timeFilter : `All Days & Times`}
          {showTimeFilters ?
            <img
              src={UpCarat}
              height={6}
              width={12}
            />
            :
            <img
              src={DownCarat}
              height={6}
              width={12}
            />}
        </button>
      </div>
      <div className="relative">
        { dropdownOpen &&
          <div className="absolute -top-2 bg-white border border-gray-light p-2.5 flex flex-col gap-2.5 w-full z-10 text-left">
            { showTopicFilters && <TopicFilter
              tagButtons={tagButtons}
              closeFilter={toggleTopicFilters}
            />}
            { showTimeFilters && <TimeFilter
              closeFilter={toggleTimeFilters}
              bundle={bundle}
            />
            }
          </div>
        }
        {topicFilters.length > 0 &&
          <div className="flex flex-wrap gap-5 items-baseline text-sm">
            {tagButtons.filter( ( tagButton ) => { return topicFilters.includes( tagButton.key ) }).map( ( tagButton ) => {
              return (
                <div
                  key={tagButton.key}
                  onClick={() => removeFilteredTopic( tagButton.key )}
                  className="flex items-center"
                >
                  <p>{tagButton.text}</p>
                  <img
                    src={Xicon}
                    height={24}
                    width={24}
                    className="p-1.5"
                  />
                </div>
              )
            }
            )}
            <div className="font-bold underline" onClick={clearFilteredTopics}><p>{`Clear All`}</p></div>
          </div>
        }
      </div>
    </div>
  )
}

export default ClassFilters