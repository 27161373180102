import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import routes from 'routes'
import { AuthRoute } from '.'

function RouteMapping() : JSX.Element {
  const location = useLocation()

  useEffect( () => {
    window.scrollTo( 0, 0 )
  }, [ location.pathname ] )

  return (
    <Routes>
      {
        routes.map( ( route ) => {
          const { authRoute, ...routeProps} = route

          if ( authRoute ) { // routeProps.path may be an array
            return <Route key={routeProps.path} {...routeProps} element={<AuthRoute {...routeProps} />} />
          }

          return <Route key={`${routeProps.path}`} {...routeProps} />
        })
      }
    </Routes>

  )
}

export default RouteMapping