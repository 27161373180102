import { useState } from "react"

const useConfirmationModal = () => {
  const [ confirmationModalTitle, setConfirmationModalTitle ] = useState<string>( `` )
  const [ confirmationModalMessage, setConfirmationModalMessage ] = useState<string>( `` )
  const [ showConfirmationModal, setShowConfirmationModal ] = useState<boolean>( false )
  const [ continueBtnText, setContinueBtnText ] = useState<string>( `Continue` )
  const [ cancelBtnText, setCancelBtnText ] = useState<string>( `Cancel` )

  const openConfirmationModal = () => {
    setShowConfirmationModal( true )
  }

  const closeConfirmationModal = () => {
    setShowConfirmationModal( false )
  }

  return {
    confirmationModalTitle,
    confirmationModalMessage,
    showConfirmationModal,
    continueBtnText,
    cancelBtnText,
    setConfirmationModalTitle,
    setConfirmationModalMessage,
    openConfirmationModal,
    closeConfirmationModal,
    setContinueBtnText,
    setCancelBtnText
  }
}

export default useConfirmationModal