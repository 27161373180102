import { INDIVIDUAL_SELECTION_BASE_PATH, TERMS_AND_CONDITIONS_PATH } from '../../routes'
import { useLocation } from 'react-router-dom'
import { SCHEDULE_BUNDLE_BASE_PATH } from '../../views/ScheduleBundle/utils/constants'

const DONT_SHOW_FOOTER_ON_THESE_ROUTES = [
  TERMS_AND_CONDITIONS_PATH,
  INDIVIDUAL_SELECTION_BASE_PATH,
  SCHEDULE_BUNDLE_BASE_PATH
]

export const Footer = () => {
  const { pathname } = useLocation()
  const showFooter = DONT_SHOW_FOOTER_ON_THESE_ROUTES.every( ( route ) => {
    return !pathname.includes( route )
  })

  return (
    showFooter &&
    <div className={`text-center pb-3 flex flex-col`}>
      <a
        target="_blank"
        rel="noreferrer"
        href={TERMS_AND_CONDITIONS_PATH}
      >
        {`Terms and Conditions`}
      </a>
      <span>{`Copyright © ${new Date().getFullYear()}`}</span>
    </div>
  )
}