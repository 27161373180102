import useScheduledClasses from 'hooks/useScheduledClasses'
import { useParams } from 'react-router-dom'
import { SchedulingErrorBlock } from 'components/SchedulingErrorBlock'
import Reschedule from './Reschedule'
import PageNavigator from 'components/pageNavigator'
import useSeriesRescheduleDisplay from '../../hooks/useSeriesRescheduleDisplay'
import { AppointmentsLoadingBox } from 'components/AppointmentsLoadingBox'

function RescheduleDisplay(): JSX.Element {
  const { classProducts, scheduledClasses, loading, error } = useScheduledClasses()

  const params = useParams<{ classId?: string }>()

  const { pageNames, seriesClasses, setSeriesClasses } = useSeriesRescheduleDisplay()

  if ( error || !params.classId ) return (
    <SchedulingErrorBlock
      message={`You may not be eligible for additional classes at this time`}
      backButton
    />
  )

  if ( loading || pageNames === null ) return (
    <div className="my-4">
      <AppointmentsLoadingBox message="Please hold tight while we get the availability for this class..." />
    </div>
  )

  if ( !pageNames?.length ) return (
    <Reschedule
      classProducts={classProducts}
      scheduledClasses={scheduledClasses}
    />
  )

  return (
    <PageNavigator
      pageNames={pageNames ?? []}
      pageClassName="w-full"
    >
      {
        ( pageNames as string[] ).map( ( path: string, classIndex: number ) => {
          return (
            <Reschedule
              key={path}
              classProducts={classProducts}
              scheduledClasses={scheduledClasses}
              classIndex={classIndex}
              totalSteps={pageNames?.length}
              seriesClasses={seriesClasses}
              setSeriesClasses={setSeriesClasses}
            />
          )
        })
      }
    </PageNavigator>
  )
}

export default RescheduleDisplay
