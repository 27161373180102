import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface KameleoonStore {
  visitorCode: string | null;
  setVisitorCode: ( _visitorCode: string ) => void;
  uxUpdateFeature: boolean;
  setUxUpdateFeature: ( _uxUpdateFeature: boolean ) => void;
}

export const useKameleoonStore = create<KameleoonStore>()(
  persist(
    ( set ) => ({
      visitorCode: null,
      setVisitorCode: ( visitorCode: string ) => set({
        visitorCode
      }),
      uxUpdateFeature: false,
      setUxUpdateFeature: ( uxUpdateFeature: boolean ) => set({
        uxUpdateFeature
      })
    }),
    {
      storage: createJSONStorage( () => sessionStorage ),
      name: `kameleoon-store`
    }
  )
)