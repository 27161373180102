import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { getAttributeMeta } from 'graphql/queries/attributeMeta'
import { ClassFilters } from 'components/ClassFilters'
import { SchedulingErrorBlock } from 'components/SchedulingErrorBlock'
import { AppointmentsLoadingBox } from 'components/AppointmentsLoadingBox'
import { IneligibleBlock } from 'components/IneligibleBlock'
import { ClassGrid } from 'components/ClassScheduler/ClassGrid'
import { ThemedModal } from 'components/ThemedModal'
import SchedulingBottomNav from 'components/SchedulingNavbar/SchedulingBottomNav'
import useScheduledClasses from 'hooks/useScheduledClasses'
import useSelectedBundle from 'hooks/useSelectedBundle'
import useFetchClasses from 'hooks/useFetchClasses'
import useClassScheduler from 'stores/useClassSchedulerStore'
import useClassFilters from 'stores/useClassFiltersStore'
import { INDIVIDUAL_CLASSES_CONFIRM_PATH } from 'views/ClassSchedule/utils/constants'
import { Class, ScheduledClass } from 'types/shopScheduleTypes'

function IndividualSelection(): JSX.Element {
  const [ modalMessage, setModalMessage ] = useState<string>( `` )
  const [ openModal, setOpenModal ] = useState<boolean>( false )

  const availableClasses = useClassScheduler( state => state.availableClasses )
  const selectedClasses = useClassScheduler( state => state.selectedClasses )
  const previouslyScheduledClasses = useClassScheduler( state => state.previouslyScheduledClasses )
  const setPreviouslyScheduledClasses = useClassScheduler( state => state.setPreviouslyScheduledClasses )
  const setCompletedClasses = useClassScheduler( state => state.setCompletedClasses )
  const setRescheduling = useClassScheduler( state => state.setRescheduling )
  const setOpenClass = useClassScheduler( state => state.setOpenClass )

  const { scheduledClasses, completedClasses } = useScheduledClasses()
  const { classes, error } = useFetchClasses()
  const topicFilters = useClassFilters( state => state.filteredTopics )
  const { selectedBundle, clearBundle } = useSelectedBundle()
  const dedupedClasses: Class[] = classes?.filter( ( classItem: Class ) => {
    return previouslyScheduledClasses?.find( ( c: ScheduledClass ) => {
      return c?.class_title?.trim() === classItem.class_title?.trim()
    }) === undefined
  }) ?? []
  const {
    data: attributeMetaData,
    loading,
    error: filterMappingError
  } = useQuery( getAttributeMeta, {
    variables: {
      attribute_code: `lactation_class_filters`,
      entity_type: `catalog_product`
    }
  })

  const navigate = useNavigate()

  useEffect( () => {
    setPreviouslyScheduledClasses( scheduledClasses )
    setCompletedClasses( completedClasses )
    setRescheduling( false )
  }, [ scheduledClasses, completedClasses ] )

  useEffect( () => {
    sessionStorage.removeItem( `has_scheduled` )
    if ( selectedBundle ) clearBundle()
  }, [] )

  const handleSeriesClasses = () => {
    let canProceed = true
    if ( selectedClasses.some( ( _class ) => _class.is_series ) ) {
      const selectedSeriesClasses = selectedClasses.filter( ( _class ) => _class.is_series )
        .sort( ( a, b ) => a.class_sequence - b.class_sequence )
        .sort( ( a, b ) => ( a.class_series_pk ?? 0 ) - ( b.class_series_pk ?? 0 ) )
      const selectedSeriesClassPks = Array.from( new Set( selectedSeriesClasses.map( ( _class ) => _class.class_series_pk ) ) )
      const seriesClassIds = availableClasses.filter( ( _class ) => selectedSeriesClassPks.includes( _class.class_series_pk ) ).map( ( _class ) => _class.class_id )
      const selectedSeriesClassIds = selectedSeriesClasses.map( ( _class ) => _class.class_id )
      seriesClassIds.forEach( ( classId ) => {
        if ( !selectedSeriesClassIds.includes( classId ) ) {
          const seriesClass = availableClasses.find( ( _class ) => _class.class_id === classId )
          const classesInSeries = availableClasses.filter( ( _class ) => _class.class_series_pk === seriesClass?.class_series_pk ).sort( ( a, b ) => a.class_sequence - b.class_sequence )
          const seriesClassIndex = classesInSeries.findIndex( ( _class ) => _class.class_id === classId )
          if ( seriesClassIndex === 0 ) {
            setModalMessage( `Hey Mama! This class is part of a ${classesInSeries.length}-class series. Next, you will schedule Part ${seriesClassIndex + 1} to take before Part ${seriesClassIndex + 2}.` )
          } else {
            setModalMessage( `Hey Mama! This class is part of a ${classesInSeries.length}-class series. Next, you will schedule Part ${seriesClassIndex + 1} to take after Part ${seriesClassIndex}.` )
          }
          setOpenModal( true )
          setOpenClass( classId )
          canProceed = false

          return
        }
      })
    }

    return canProceed
  }

  const closeModal = () => setOpenModal( false )

  const toReview = () => {
    const canProceed = handleSeriesClasses()
    if ( canProceed ) navigate( `${INDIVIDUAL_CLASSES_CONFIRM_PATH}${window.location.search}` )
  }

  if ( error ) return <SchedulingErrorBlock message={error} />

  if ( !classes || loading )
    return (
      <AppointmentsLoadingBox message="Please hold tight while we grab your available classes..." />
    )

  if ( !dedupedClasses?.length ) return <IneligibleBlock />

  const filteredClasses = dedupedClasses.filter( ( classDetails ) => {
    if ( !topicFilters?.length ) return true

    return topicFilters.includes( classDetails?.category )
  })

  return (
    <div>
      <div className="flex gap-5 justify-center items-center flex-col md:my-10 px-5 text-center pb-24">
        <p className="text-3xl md:text-4xl font-header">{`Choose Your Classes`}</p>
        <p className="text-sm md:text-xl">{`All classes are online and taught live by certified instructors!`}</p>
        {attributeMetaData?.customAttributeMetadata?.items?.length &&
          attributeMetaData?.customAttributeMetadata?.items[0]
            ?.attribute_options &&
          !filterMappingError && (
          <ClassFilters
            classes={dedupedClasses}
            filterMapping={
              attributeMetaData.customAttributeMetadata.items[0]
                .attribute_options
            }
          />
        )}
        <ClassGrid classes={filteredClasses} />
      </div>
      <SchedulingBottomNav toReview={toReview} />
      <ThemedModal
        message={modalMessage}
        open={openModal}
        handleClose={closeModal}
      />
    </div>
  )
}

export default IndividualSelection