import { gql } from '@apollo/client'

export const productsFragment = gql`
  fragment Products on Products {
    items {
      id
      name
      sku
      short_description {
        html
      }
      description {
        html
      }
      lactation_class_filters
      course_material_link
      custom_attributes {
        attribute_code
        value
      }
      small_image {
        label
        url
      }
      image {
        label
        url
      }
      media_gallery_entries {
        types
        file
      }
      scheduling_app_description
    }
  }
`
