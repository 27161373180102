import { getSource } from "utils/getSource"

const getBundleTimeslots = async ( sku: string, timezone: string, auth: string ) => {
  const response = await fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/bundles/${sku}?tz=${timezone}&source=${getSource()}`, {
    headers: {
      "Authorization": auth
    }
  })

  return await response.json()
}

export default getBundleTimeslots