import React from 'react'
import { createClient, Environment, IExternalLogger, KameleoonProvider, LogLevel } from '@kameleoon/react-sdk'
import KameleoonInit from './KameleoonInit'
import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/react'

export class CustomLogger implements IExternalLogger {
  public log( level: LogLevel, message: string ): void {
    Sentry.addBreadcrumb({
      'type': `default`,
      'category': `kameleoon`,
      'data': undefined,
      'level': level === LogLevel.ERROR ? Severity.Error : level === LogLevel.WARNING ? Severity.Warning : level === LogLevel.INFO ? Severity.Info : level === LogLevel.DEBUG ? Severity.Debug : Severity.Log,
      'message': message,
      'timestamp': Date.now()
    })
  }
}

function KameleoonSetup({ children }: { children: React.ReactNode }) {
  const client = createClient({
    siteCode: process.env.REACT_APP_KAMELEOON_SITE_CODE || ``,

    configuration: {
      updateInterval: 1,
      environment: process.env.REACT_APP_ENVIRONMENT === `production` ? Environment.Production : process.env.REACT_APP_ENVIRONMENT === `stage` ? Environment.Staging : Environment.Development
    },
    externals: {
      logger: new CustomLogger()
    }
  })

  // The `DEBUG` log level allows logging extra information on the SDK's internal processes.
  // It extends the `INFO` log level.
  client.setLogLevel( LogLevel.DEBUG )

  return (
    <KameleoonProvider client={client}>
      <KameleoonInit />
      {children}
    </KameleoonProvider>
  )
}

export default KameleoonSetup