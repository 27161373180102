import { Xicon } from 'assets'
import { Modal } from 'components/modal'

type ConfirmationModalProps = {
  title?: string;
  message?: string;
  continueBtnText?: string;
  cancelBtnText?: string;
  open: boolean;
  handleClose: () => void;
  onConfirmation: () => void;
  onCancellation?: () => void;
  handleClickOff?: () => void;
}

const ConfirmationModal = ({
  title = ``,
  message = ``,
  continueBtnText = `Continue`,
  cancelBtnText = `Cancel`,
  open,
  handleClose,
  onConfirmation,
  onCancellation = handleClose,
  handleClickOff
} : ConfirmationModalProps ) : JSX.Element => {
  return (
    <Modal
      isOpen={open}
      defaultCloseButton={false}
      containerStyling=""
      onClose={handleClickOff ?? handleClose}
    >
      <div className="bg-white text-black py-12 px-8 flex flex-col gap-5 justify-center items-center text-center">
        <button className="absolute top-4 right-4 p-0" onClick={handleClose}>
          <img
            src={Xicon}
            alt="close"
            height={20}
            width={20}
          />
        </button>
        {title && <h3 className="font-header text-xl">{title}</h3>}
        {message && <p className="whitespace-pre-line">{message}</p>}
        <button
          className="standard-button uppercase tracking-widest text-sm w-full button-px-0"
          onClick={onConfirmation}
        >{continueBtnText}</button>
        <button
          className="outline-button uppercase tracking-widest text-sm w-full button-px-0"
          onClick={onCancellation}
        >
          {cancelBtnText}
        </button>
      </div>
    </Modal>
  )
}

export default ConfirmationModal