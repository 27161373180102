import { Modal } from 'components/modal'
import { Bundle } from 'types/shopScheduleTypes'
import { Xicon } from 'assets'
import { ReviewDisplay } from 'components/ReviewDisplay'
import ReactHtmlParser from 'react-html-parser'

type AdditionalDetailsProps = {
  bundle: Bundle | null;
  handleClose: () => void;
  modalOpen: boolean;
}

const BundleDetailsModal = ({ bundle, modalOpen, handleClose } : AdditionalDetailsProps ) : JSX.Element => {

  return (
    <Modal
      isOpen={modalOpen}
      onClose={handleClose}
      defaultCloseButton={false}
      iconId="magnifyingGlass"
    >
      <div className="flex flex-col items-center justify-center gap-2 px-8 md:px-12 pt-5 pb-10">
        <div
          className="absolute top-1 right-1 cursor-pointer hover:opacity-80 p-2"
          onClick={handleClose}
        >
          <img
            src={Xicon}
            alt="close modal icon"
            width={15}
            height={15}
          />
        </div>
        <p className="text-3xl font-header mb-2 mt-6">{bundle?.bundle_title}</p>
        <div className="shadow-sm mb-5 w-full">
          <img
            src={bundle?.image_path}
            alt="group bundle image"
            className="w-full object-contain bg-gray-white rounded"
          />
        </div>
        {
          bundle?.reviews && bundle?.number_of_reviews &&
          <ReviewDisplay reviews={bundle?.reviews} number_of_reviews={bundle?.number_of_reviews} vertical={false} />
        }
        <div className="mt-5 text-base md:text-lg">
          {
            bundle && bundle?.bundle_short_description?.length > 0 &&
            ReactHtmlParser( bundle.bundle_short_description )
          }
        </div>
        <p
          className="a"
          onClick={handleClose}
        >{`Go back`}</p>
      </div>
    </Modal>
  )
}

export default BundleDetailsModal