import useApolloClient from 'apollo/apolloClient'
import { getProductsQueryBySkuArray } from 'graphql/queries/products'
import useActiveTags from 'hooks/useActiveTag'
import { useEffect, useState } from 'react'
import useAuthStore from 'stores/auth'
import { MagentoProduct } from 'types'
import { Class } from 'types/shopScheduleTypes'
import { reportToSentry } from 'utils/reportToSentry'
import fetchClasses from 'utils/fetchClasses'

function useFetchClasses(): { classes: Class[] | null, error: string } {

  const [ classes, setClasses ] = useState<Class[] | null>( null )
  const [ filteredClasses, setFilteredClasses ] = useState<Class[] | null>( null )
  const [ error, setError ] = useState<string>( `` )

  const { buildAuthorizer } = useAuthStore()
  const authHeader = buildAuthorizer()
  const apolloClient = useApolloClient()

  const defaultErrorMessage = `Oops... We encountered an error getting the available classes for you.`

  useEffect( () => {
    if ( authHeader && !classes ) {
      fetchClasses( authHeader )
        .then( data => {
          if ( !data?.data?.classes?.length ) {
            reportToSentry( new Error( `Shop and Schedule: User not eligible for classes` ), {
              authHeader: JSON.stringify( authHeader )
            })

            return setError( `No classes available. You may not be eligible for classes at this time.` )
          }
          if ( data?.data?.classes && !data?.errors?.length && data?.meta?.status === `OK` ) return getClassProducts( data.data.classes )
          reportToSentry( new Error( `Shop and Schedule: Error getting classes` ), {
            authHeader: JSON.stringify( authHeader )
          })

          return setError( defaultErrorMessage )
        })
        .catch( ( error: Error ) => {
          reportToSentry( new Error( `Shop and Schedule: Error getting classes`, {
            cause: error
          }), {
            authHeader: JSON.stringify( authHeader )
          })

          return setError( defaultErrorMessage )
        })
    }

    return () => {
      filterClassesSubscription()
    }

  }, [ authHeader ] )

  const filterClassesSubscription = useActiveTags.subscribe( ( state ) => {
    setFilteredClasses( classes ? classes.filter( ( classDetails ) => {
      if ( !state?.activeTag ) return true
      if ( classDetails?.category && state.activeTag === classDetails?.category ) return true

      return false
    }) : [] )
  })

  const getClassProducts = async ( classData: Class[] ) => {

    const classSkusArray: string[] = classData.filter( ( classItem: Class ) => {
      return Boolean( classItem.class_sku )
    }).map( ( classItem: Class ) => {
      return classItem.class_sku
    })

    const classProductsResult = await apolloClient.query({
      query: getProductsQueryBySkuArray,
      fetchPolicy: `network-only`,
      variables: {
        skus: classSkusArray,
        pageSize: classSkusArray.length
      }
    })
      .catch( ( error: Error ) => {
        reportToSentry( new Error( `Shop and Schedule: Product query error`, {
          cause: error
        }), {
          classSkusArray: JSON.stringify( classSkusArray )
        })

        return setError( defaultErrorMessage )
      })

    if ( !classProductsResult?.data?.resupplyProducts?.items ) {
      reportToSentry( new Error( `Shop and Schedule: Product query error` ), {
        classSkusArray: JSON.stringify( classSkusArray )
      })

      return setError( defaultErrorMessage )
    }

    const updateClassData = classData.map( ( classItem: Class ) => {
      const magentoClassDetails: MagentoProduct = classProductsResult.data.resupplyProducts.items.find( ( resultClass: MagentoProduct ) => {
        return classItem.class_sku === resultClass.sku
      })
      if ( magentoClassDetails ) {
        return {
          ...classItem,
          small_image_path: magentoClassDetails?.small_image?.url ?? ``,
          image_path: magentoClassDetails?.image?.url ?? ``,
          internal_class_title: classItem.class_title,
          class_title: magentoClassDetails?.name,
          class_description: magentoClassDetails?.short_description?.html ?? ``,
          category: magentoClassDetails.lactation_class_filters
        }
      } else {
        return {
          ...classItem,
          internal_class_title: classItem.class_title
        }
      }


    }) as Class[]

    setClasses( [ ...updateClassData ] )

    return setFilteredClasses( [ ...updateClassData ] )
  }

  return {
    classes: filteredClasses,
    error
  }
}

export default useFetchClasses