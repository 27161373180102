import { TextInput, CheckboxOptions, RadioButtonOptions } from 'components/Forms/components'
import Select from 'components/Select'
import TextField from 'components/TextField'
import { PatientHistoryForm } from 'types/integratedPatientHistory'

function PageOneQuestions({ page, values, hideQuestions, onChange, onMultiSelectChange } :
  {
    page: number,
    values: PatientHistoryForm,
    hideQuestions: boolean,
    onChange: ( _name: string, _value: any ) => void,
    onMultiSelectChange: ( _name: string, _itemKey: string, _value: boolean, _additional?: string ) => void
  }) : JSX.Element | null {

  if ( page !== 1 ) return null

  return (
    <div className="w-full space-y-10">
      <TextInput
        className="input w-full"
        type="text"
        name="email"
        description="What email address can we best reach you at? If you're taking maternity leave, please ensure this email address is one you will be regularly checking."
        value={values.email}
        label="Email Address"
        onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
      />
      <div className="flex flex-col gap-2">
        <Select
          name="language_for_appointment"
          selectValues={[{
            id: `placeholder`,
            name: `Select a language`,
            value: ``,
            disabled: true
          },
          {
            id: `en`,
            name: `English`,
            value: `English`
          },
          {
            id: `sp`,
            name: `Spanish`,
            value: `Spanish`
          },
          {
            id: `asl`,
            name: `American Sign Language`,
            value: `American Sign Language`
          },
          {
            id: `other`,
            name: `Other`,
            value: `Other`
          }]}
          label="Language for Appointment"
          description="If you would like to request an interpreter, please write in your preferred language."
          onChange={( value ) => {
            onChange( `language_for_appointment`, value )
          }}
          inputProps={{
            defaultValue: ``
          }}
        />
        {
          values?.language_for_appointment === `Other` &&
          <TextInput
            className="input w-full"
            type="text"
            name="other_language_for_appointment"
            description="Please write in your other preferred language below."
            value={values.other_language_for_appointment}
            onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
          />
        }
      </div>
      <RadioButtonOptions
        id="type_of_appointment"
        name="type_of_appointment"
        label="Type of Appointment Requested"
        value={values.type_of_appointment}
        options={[
          {
            id: `birth_plan_appointment`,
            name: `Birth Plan Appointment (Please note that birth plans require two appointments. Your provider can help you schedule the next visit.)`
          },
          {
            id: `breastfeeding_appointment`,
            name: `Breastfeeding Appointment`
          },
          {
            id: `breast_pump_appointment`,
            name: `Breast Pump Appointment`
          },
          {
            id: `going_back_to_work_appointment`,
            name: `Going Back to Work Appointment`
          },
          {
            id: `sleep_appointment`,
            name: `Sleep Appointment`
          }
        ]}
        onChange={onChange}
        required
      />
      <TextInput
        type="textarea"
        description="Explain the most important issue you would like information about or support with during the appointment."
        id="most_important_issue"
        name="most_important_issue"
        value={values.most_important_issue}
        label="Most Important Issue"
        onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
        required
      />
      <TextInput
        className="input w-full"
        type="text"
        description="Please write the name of your healthcare provider(s) who you authorize to have access to your medical record.  Write MD, CNM, LM, etc. after the name. Only include the provider name if you authorize us to release information from your medical record to this provider. If you do not authorize this release, leave this question blank."
        name="release_of_health_information"
        value={values.release_of_health_information}
        label="Release of Health Information"
        onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
      />
      <RadioButtonOptions
        id="first_baby"
        name="first_baby"
        value={values.first_baby}
        label="Is This Your First Baby?"
        options={[
          {
            id: `yes`,
            name: `Yes`
          },
          {
            id: `no`,
            name: `No`
          }
        ]}
        onChange={onChange}
      />
      <RadioButtonOptions
        id="have_had_baby"
        name="have_had_baby"
        label="Have You Had Your Baby?"
        required
        value={values.have_had_baby}
        options={[
          {
            id: `yes`,
            name: `Yes`,
            value: `Yes`
          },
          {
            id: `no`,
            name: `No`,
            value: `No`
          }
        ]}
        onChange={onChange}
      />
      {
        !hideQuestions &&
            <>
              <TextField
                type="dob"
                hideDOBLabels
                label="Baby's Date of Birth"
                onChange={value => { return onChange( `baby_dob`, value ) }}
                required={false}
              />
              <RadioButtonOptions
                id="birth_type"
                name="birth_type"
                label="What Type of Birth Did You Have?"
                required
                value={values.birth_type}
                options={[
                  {
                    id: `vaginal`,
                    name: `Vaginal`,
                    value: `Vaginal`
                  },
                  {
                    id: `cesarean`,
                    name: `Cesarean`,
                    value: `Cesarean`
                  },
                  {
                    id: `induced`,
                    name: `Induced`,
                    value: `Induced`
                  }
                ]}
                onChange={onChange}
              />
              <TextInput
                className="input w-full"
                type="text"
                name="weeks_pregnant_at_birth"
                value={values.weeks_pregnant_at_birth}
                label="Number of Weeks Pregnant You Were at the Time of Baby's Birth"
                onChange={({ target: { name, value } }) => { return onChange( name, value ) }}

              />
              <TextInput
                className="input w-full"
                type="text"
                name="baby_full_name"
                value={values.baby_full_name}
                description="If multiples, write down all names and birth dates."
                label="Baby's Full Name"
                onChange={({ target: { name, value } }) => { return onChange( name, value ) }}

              />
              <TextInput
                className="input w-full"
                type="text"
                name="baby_weight_at_birth"
                value={values.baby_weight_at_birth}
                description="If multiples, write down all relevant info."
                label="Baby's Weight at Birth"
                onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
              />
              <TextInput
                className="input w-full"
                type="text"
                name="baby_most_recent_weight"
                value={values.baby_most_recent_weight}
                description="If multiples, write down all relevant info."
                label="Baby's Most Recent Weight"
                onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
              />
              <TextField
                type="dob"
                hideDOBLabels
                label="Date of Baby's Most Recent Weight Measurement"
                onChange={value => { return onChange( `baby_most_recent_weight_date`, value ) }}
                required={false}
              />
              <CheckboxOptions
                id="baby_medical_history"
                name="baby_medical_history"
                label="Baby's Medical History"
                description="Please share any additional medical information you would like us to know about your baby's health."
                options={values.baby_medical_history}
                onChange={( name, value, additional ) => { return onMultiSelectChange( `baby_medical_history`, name, value, additional ) }}
              />
              <TextInput
                className="input w-full"
                type="textarea"
                name="baby_prescription_medication"
                value={values.baby_prescription_medication}
                label="Baby's Prescription Medications and Vitamins/Minerals/Herbs"
                onChange={({ target: { name, value } }) => { return onChange( name, value ) }}

              />
            </>
      }
      <CheckboxOptions
        id="your_medical_history"
        name="your_medical_history"
        label="Your Personal Medical History"
        description="Check any that have applied to you now or in the past."
        options={values.your_medical_history}
        onChange={( name, value, additional ) => { return onMultiSelectChange( `your_medical_history`, name, value, additional ) }}
      />
      <TextInput
        className="input w-full"
        type="textarea"
        name="your_medical_conditions"
        value={values.your_medical_conditions}
        label="Your Medical Conditions"
        description="Please share any additional medical information you would like us to know about your health."
        onChange={({ target: { name, value } }) => { return onChange( name, value ) }}

      />
      <TextInput
        className="input w-full"
        type="textarea"
        name="your_prescription_medication"
        value={values.your_prescription_medication}
        label="Your Prescription Medications and Vitamins/Minerals/Herbs"
        description="Please include name(s) of the medication(s) and supplement(s) you take, dose, and how often you take it."
        onChange={({ target: { name, value } }) => { return onChange( name, value ) }}

      />
      <CheckboxOptions
        id="your_family_medical_history"
        name="your_family_medical_history"
        label="Your Family Medical History"
        description="Check any that apply to your parents or siblings."
        options={values.your_family_medical_history}
        onChange={( name, value, additional ) => { return onMultiSelectChange( `your_family_medical_history`, name, value, additional ) }}
      />
    </div>
  )
}

export default PageOneQuestions