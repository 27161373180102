import { CalendarIcon, RightCarat, Xicon } from "assets"
import { Modal } from "components/modal"
import { useState } from "react"
import { DayModifiers } from "react-day-picker"
import { TimeSlot } from "types/shopScheduleTypes"
import "react-day-picker/lib/style.css"
import { StyledDayPickerV2 } from "components/DatePicker/styled"
import { dateFormatter } from "utils/time"

type DatePickerModalProps = {
  selectedDate: Date | undefined;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  filterByDate: boolean;
  setFilterByDate: React.Dispatch<React.SetStateAction<boolean>>;
  upcomingTimeSlots: TimeSlot[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const DatePickerModal = ({ selectedDate, setSelectedDate, filterByDate, setFilterByDate, upcomingTimeSlots, setPage }: DatePickerModalProps ) => {
  const [ modalOpen, setModalOpen ] = useState<boolean>( false )
  const fromMonth = new Date( upcomingTimeSlots[0]?.scheduled_date )
  const toMonth = new Date( upcomingTimeSlots[upcomingTimeSlots.length - 1]?.scheduled_date )

  const handleSearch = () => {
    if ( !selectedDate ) {
      setFilterByDate( false )
    } else {
      setFilterByDate( true )
      setPage( 1 )
    }
    setModalOpen( false )
  }

  const handleDayClick = ( day: Date, modifiers: DayModifiers ): void => {
    if ( modifiers.disabled ) return
    if ( selectedDate?.getTime() === day.getTime() ) {
      setSelectedDate( undefined )

      return
    }
    setSelectedDate( day )
  }

  const noUpcomingTimeSlots = ( day: Date ): boolean => {
    for ( let i = 0; i < upcomingTimeSlots.length; i++ ) {
      const date = new Date( upcomingTimeSlots[i].scheduled_date )
      if ( date.getUTCFullYear() === day.getUTCFullYear() && date.getUTCMonth() === day.getUTCMonth() && date.getUTCDate() === day.getUTCDate() ) {
        return false
      }
    }

    return true
  }

  const hasUpcomingTimeSlots = ( day: Date ): boolean => {
    for ( let i = 0; i < upcomingTimeSlots.length; i++ ) {
      const date = new Date( upcomingTimeSlots[i].scheduled_date )
      if ( date.getUTCFullYear() === day.getUTCFullYear() && date.getUTCMonth() === day.getUTCMonth() && date.getUTCDate() === day.getUTCDate() ) {
        return true
      }
    }

    return false
  }

  const renderDay = ( day : Date ) => {
    return (
      <div className="date-number-wrapper">
        <div className="date-number">{day.getDate()}</div>
      </div>
    )
  }

  return (
    <div>
      <button
        className="p-0 flex flex-row gap-1 items-center uppercase tracking-widest font-semibold text-sm"
        onClick={() => {
          if ( selectedDate ) {
            setFilterByDate( false )
            setSelectedDate( undefined )
          } else {
            setModalOpen( true )
          }
        }}
      >
        {filterByDate ?
          <span>{`Clear`}</span>
          :
          <>
            <img
              src={CalendarIcon}
              width={18}
              height={18}
            />
            <span>{`Search`}</span>
          </>
        }
        <img
          src={RightCarat}
          className="opacity-50"
          width={10}
          height={10}
        />
      </button>

      <Modal isOpen={modalOpen} onClose={() => setModalOpen( false )} defaultCloseButton={false} >
        <div className="flex flex-col gap-8 p-8 py-12">
          <div>
            <h3 className="text-center text-xl font-header">{`Search By Date`}</h3>
          </div>
          <StyledDayPickerV2
            selectedDays={selectedDate}
            onDayClick={handleDayClick}
            disabledDays={noUpcomingTimeSlots}
            modifiers={{
              hasUpcomingTimeSlots
            }}
            renderDay={renderDay}
            initialMonth={fromMonth}
            fromMonth={fromMonth}
            toMonth={toMonth}
            weekdaysShort={[ `S`, `M`, `T`, `W`, `Th`, `F`, `S` ]}
          />
          <div className="mx-auto">
            <p>{selectedDate && dateFormatter.format( selectedDate )}</p>
          </div>
          <button
            onClick={handleSearch}
            className="border border-secondary rounded-button w-fit mx-auto py-2 px-10 "
          >
            <span className="uppercase text-secondary font-semibold text-sm tracking-widest">{`Search`}</span>
          </button>
          <button className="absolute top-0 right-0 p-4">
            <img
              src={Xicon}
              alt="close date picker"
              width={14}
              height={14}
              onClick={() => setModalOpen( false )}
            />
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default DatePickerModal