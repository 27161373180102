export interface ButtonButtonGroup {
  key: string
  selected: boolean
  text: string;
}

interface ButtonGroupProps {
  buttons: ButtonButtonGroup[];
  toggleButton: ( _button: ButtonButtonGroup ) => void;
  hideSelectText?: boolean;
}

function ButtonGroup({ buttons, toggleButton, hideSelectText = false } : ButtonGroupProps ) : JSX.Element {
  return (
    <div className="flex flex-col md:flex-row items-center gap-2">
      {
        !hideSelectText &&
        <p className="text-xs md:text-sm">{`Select One:`}</p>
      }
      <div className="button-group">
        {
          buttons.map( ( button ) => {
            return (
              <button
                key={button.key}
                className={`button-group-button ${ button.selected && `button-group-button-selected`}`}
                onClick={() => { toggleButton( button ) }}
              >
                {button.text}
              </button>
            )
          })
        }
      </div>
    </div>
  )
}

export default ButtonGroup