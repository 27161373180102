import useSelectedBundle from 'hooks/useSelectedBundle'
import { useEffect, useState } from 'react'
import useAuthStore from 'stores/auth'
import { Bundle, Class, ScheduledClass } from 'types/shopScheduleTypes'
import { reportToSentry } from 'utils/reportToSentry'
import getBundleTimeslots from 'utils/getBundleTimeslots'
import useScheduledClasses from 'hooks/useScheduledClasses'
import useApolloClient from 'apollo/apolloClient'
import { getProductsQueryBySkuArray } from 'graphql/queries/products'
import { MagentoProduct } from 'types'

type GetBundleDetailsReturn = {
  bundleDetails: Bundle | null;
  error: string;
  loading: boolean;
}

export const useGetBundleDetails = ( timezone = `US/Eastern` ): GetBundleDetailsReturn => {
  const [ bundleDetails, setBundleDetails ] = useState<Bundle | null>( null )
  const [ loading, setLoading ] = useState<boolean>( true )
  const [ error, setError ] = useState<string>( `` )

  const { selectedBundle } = useSelectedBundle()
  const { loading: loadingCompletedClasses, completedClasses } = useScheduledClasses()
  const { buildAuthorizer } = useAuthStore()
  const authHeader = buildAuthorizer()
  const apolloClient = useApolloClient()

  const defaultErrorMessage = `Oops... We encountered an error getting the available classes for you.`

  useEffect( () => {
    if ( selectedBundle && timezone && !loadingCompletedClasses && ( !bundleDetails?.classes?.length || !bundleDetails?.classes[0][`${timezone}_timeslots`] ) ) {
      setLoading( true )
      getBundleTimeslots( selectedBundle.bundle_sku, timezone, authHeader ).then( ( data ) => {
        if ( data?.data && data?.meta?.status === `OK` ) {
          // This is to save the timeslots according to timezone so there is no need
          // to refetch if patient navigates back to an already fetched timezone

          // here we are filtering out series classes that have been completed already since they don't need to be scheduled again
          const bundleClassData = data.data.classes.filter( ( classItem: Class ) => {
            if ( !classItem.is_series ) return true
            const completedSeriesClass = completedClasses.find( ( completedClass: ScheduledClass ) => {
              return completedClass.class_title?.toLowerCase().trim() === classItem.class_title?.toLowerCase().trim()
            })

            return !completedSeriesClass
          })

          formatTimeslots( Object.assign({}, data.data, {
            classes: bundleClassData
          }) )
        } else {
          setError( `Oops... We encountered an exception attempting to get the available times for your bundle.` )
          reportToSentry( new Error( `Shop and Schedule: Error attempting to get bundle detail time slots` ), {
            selectedBundle: JSON.stringify( selectedBundle ),
            authHeader
          })
        }

        return setLoading( false )
      })
        .catch( ( error: Error ) => {
          reportToSentry( new Error( `Shop and Schedule: Error attempting to get bundle detail time slots`, {
            cause: error
          }), {
            selectedBundle: JSON.stringify( selectedBundle ),
            authHeader
          })
          setError( `Oops... We encountered an exception attempting to get the available times for your bundle.` )

          return setLoading( false )
        })
    }

  }, [ timezone, loadingCompletedClasses ] )

  const getMagentoClassData = async ( classData: Class[] ) => {
    const classSkusArray: string[] = classData.filter( ( classItem: Class ) => {
      return Boolean( classItem.class_sku )
    }).map( ( classItem: Class ) => {
      return classItem.class_sku
    })

    const classProductsResult = await apolloClient.query({
      query: getProductsQueryBySkuArray,
      fetchPolicy: `network-only`,
      variables: {
        skus: classSkusArray,
        pageSize: classSkusArray.length
      }
    })
      .catch( ( error: Error ) => {
        reportToSentry( new Error( `Shop and Schedule: Product query error`, {
          cause: error
        }), {
          classSkusArray: JSON.stringify( classSkusArray )
        })

        return setError( defaultErrorMessage )
      })

    if ( !classProductsResult?.data?.resupplyProducts?.items ) {
      reportToSentry( new Error( `Shop and Schedule: Product query error` ), {
        classSkusArray: JSON.stringify( classSkusArray )
      })

      return setError( defaultErrorMessage )
    }

    return classProductsResult.data.resupplyProducts.items
  }

  const formatTimeslots = async ( bundleData: Bundle ) => {
    const magentoClassData = await getMagentoClassData( bundleData.classes )
    const updateBundleDetails = Object.assign({}, bundleData, {
      image_path: selectedBundle?.image_path ?? ``,
      bundle_short_description: selectedBundle?.bundle_short_description ?? ``,
      bundle_description: selectedBundle?.bundle_description ?? ``
    })
    updateBundleDetails.classes.forEach( ( classDetails: Class, i: number ) => {
      const magentoClassDetails: MagentoProduct = magentoClassData?.find( ( resultClass: MagentoProduct ) => {
        return classDetails.class_sku === resultClass.sku
      })
      const selectedBundleClassData = selectedBundle?.classes?.find( ( classItem: Class ) => {
        return classItem.class_title?.toLowerCase().trim() === classDetails.class_title?.toLowerCase().trim()
      })
      // we get class sequence from /bundles but not from bundle details endpoint
      const selectedBundleClassIndex = selectedBundle?.classes?.findIndex( ( dataClass: Class ) => {
        return classDetails.class_title?.toLowerCase().trim() === dataClass.class_title?.toLowerCase().trim()
      })
      const dataClassIndex = bundleData.classes.findIndex( ( dataClass: Class ) => {
        return classDetails.class_title?.toLowerCase().trim() === dataClass.class_title?.toLowerCase().trim()
      })
      updateBundleDetails.classes[i] = Object.assign({}, updateBundleDetails.classes[i], {
        internal_class_title: bundleData.classes[dataClassIndex].class_title,
        [`${timezone}_timeslots`]: bundleData.classes[dataClassIndex].timeslots,
        class_duration: bundleData.classes[dataClassIndex].timeslots?.length ? bundleData.classes[dataClassIndex].timeslots[0].duration_minutes : ``,
        small_image_path: selectedBundleClassData?.small_image_path ?? ``,
        image_path: selectedBundleClassData?.image_path ?? ``,
        class_sequence: selectedBundleClassIndex !== null && selectedBundleClassIndex !== undefined ? selectedBundle?.classes[selectedBundleClassIndex]?.class_sequence : null,
        skip_scheduling: ( bundleDetails?.classes?.length || 0 ) > dataClassIndex ? bundleDetails?.classes[dataClassIndex]?.skip_scheduling : undefined,
        selected_timeslot: ( bundleDetails?.classes?.length || 0 ) > dataClassIndex ? bundleDetails?.classes[dataClassIndex]?.selected_timeslot : undefined,
        class_description: magentoClassDetails?.short_description?.html ?? ``,
        category: magentoClassDetails?.lactation_class_filters ?? ``
      })
      delete updateBundleDetails.classes[i].timeslots
    })

    setBundleDetails( updateBundleDetails )
  }

  return {
    bundleDetails,
    error,
    loading
  }
}