import { BundleClassSelection } from "components/ClassSchedule/BundleClassSelection"
import { useKameleoonStore } from "stores/kameleoonStore"
import { BundleSelection as BundleSelectionV1 } from "views/BundleSelection"

const BundleSelection = () => {
  const uxUpdateFeature = useKameleoonStore( store => store.uxUpdateFeature )

  if ( uxUpdateFeature ) {
    return <BundleClassSelection />
  }

  return <BundleSelectionV1 />
}

export default BundleSelection