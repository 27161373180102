import { useEffect, useRef } from "react"
import useClassFiltersStore, { TIME_FILTERS } from "stores/useClassFiltersStore"

type TimeFilterProps = {
  closeFilter: () => void
  bundle?: boolean
}
const TimeFilter = ({ closeFilter, bundle }: TimeFilterProps ) => {
  const clearTimeFilter = useClassFiltersStore( state => state.clearTimeFilter )
  const addTimeFilter = useClassFiltersStore( state => state.setTimeFilter )

  const filterTime = ( timeFilter?: string ) => {
    if ( timeFilter === undefined ) {
      clearTimeFilter()
    } else {
      addTimeFilter( timeFilter )
    }
    closeFilter()
  }

  const timeFilterDropdownRef = useRef<HTMLDivElement>( null )
  useEffect( () => {
    const handleClickOutside = ( event: MouseEvent ) => {
      if ( !timeFilterDropdownRef.current?.contains( event.target as Node ) ) {
        if ( !( event.target instanceof HTMLButtonElement && event.target.name === `timeFilter` ) ) {
          closeFilter()
        }
        document.removeEventListener( `mousedown`, handleClickOutside )
      }
    }
    document.addEventListener( `mousedown`, handleClickOutside )
  }, [] )

  return (
    <div className="flex flex-col gap-2" ref={timeFilterDropdownRef}>
      <div onClick={() => filterTime()}>{`All Days & Times`}</div>
      <div onClick={() => filterTime( TIME_FILTERS.morning )}>{`Morning Only`}</div>
      <div onClick={() => filterTime( TIME_FILTERS.evening )}>{`Evening Only`}</div>
      {!bundle && <div onClick={() => filterTime( TIME_FILTERS.weekend )}>{`Weekends Only`}</div>}
    </div>

  )
}

export default TimeFilter