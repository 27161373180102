import { useKameleoonStore } from "stores/kameleoonStore"
import { ScheduleBundle } from "views/ScheduleBundle"
import { Bundle as ScheduleBundleClass } from "components/ClassSchedule/Bundle"

const Bundle = () => {
  const uxUpdateFeature = useKameleoonStore( store => store.uxUpdateFeature )

  if ( uxUpdateFeature ) {
    return <ScheduleBundleClass />
  }

  return <ScheduleBundle />
}

export default Bundle