import { useNavigate } from "react-router-dom"
import { ConfirmationModal } from "components/ConfirmationModal"
import useConfirmationModal from "hooks/useConfirmationModal"
import useScheduledClasses from "hooks/useScheduledClasses"
import useClassScheduler from "stores/useClassSchedulerStore"
import { Class, ClassDescription, TimeSlot } from "types/shopScheduleTypes"

type ConfirmCardProps = {
  classDetails?: Class,
  timeSlot?: TimeSlot,
  schedulePath: string
}
const ConfirmCard = ({ classDetails, timeSlot, schedulePath }: ConfirmCardProps ): JSX.Element => {
  const series = Boolean( classDetails?.is_series && classDetails.class_series_pk )

  const rescheduling = useClassScheduler( state => state.rescheduling )
  const timezone = useClassScheduler( state => state.timezone )
  const selectedClassPks = useClassScheduler( state => state.selectedClassPks )
  const selectedClasses = useClassScheduler( state => state.selectedClasses )
  const availableClasses = useClassScheduler( state => state.availableClasses )
  const setOpenClass = useClassScheduler( state => state.setOpenClass )
  const removeClassSelection = useClassScheduler( state => state.removeClassSelection )

  const { completedClasses } = useScheduledClasses()
  const {
    confirmationModalTitle,
    confirmationModalMessage,
    showConfirmationModal,
    continueBtnText,
    cancelBtnText,
    setConfirmationModalTitle,
    setConfirmationModalMessage,
    openConfirmationModal,
    closeConfirmationModal,
    setContinueBtnText,
    setCancelBtnText
  } = useConfirmationModal()
  const navigate = useNavigate()

  const getSeriesClasses = ( _classSeriesPk: number ): ClassDescription[] => {
    return availableClasses.filter( ( availableClass: ClassDescription ) => {
      return availableClass.class_series_pk === _classSeriesPk
    }).filter( ( availableSeriesClass: ClassDescription ) => {
      return completedClasses?.find( ( completedClass ) => {
        return completedClass.class_id === availableSeriesClass.class_id
      }) === undefined
    })
      .sort( ( a: ClassDescription, b: ClassDescription ) => {
        return a.class_sequence - b.class_sequence
      })
  }

  const changeDateOrTime = () => {
    if ( classDetails?.is_series && classDetails.class_series_pk ) {
      showSeriesActionModal()

      return
    }
    setOpenClass( classDetails?.class_id ?? `` )
    navigate( schedulePath )
  }

  const removeClass = () => {
    if ( classDetails !== undefined && timeSlot !== undefined ) {
      removeClassSelection( classDetails, timeSlot )
    }
    closeConfirmationModal()
  }

  const openRemoveModal = () => {
    if ( classDetails?.is_series && classDetails.class_series_pk ) {
      showSeriesActionModal()

      return
    }

    setConfirmationModalTitle( `Are you sure you want to remove this class?` )
    setContinueBtnText( `Yes, Remove It` )
    setCancelBtnText( `No, Keep It` )
    openConfirmationModal()
  }

  const showSeriesActionModal = () => {
    if ( !classDetails ) return
    const seriesClasses = getSeriesClasses( classDetails.class_series_pk ?? 0 ).filter( ( availableSeriesClass: ClassDescription ) => {
      return completedClasses?.find( ( completedClass ) => {
        return completedClass.class_id === availableSeriesClass.class_id
      }) === undefined
    })
    const currentClassIndex = seriesClasses.findIndex( ( _class: ClassDescription ) => _class.class_id === classDetails.class_id ) + 1
    setConfirmationModalTitle( `Are you sure?` )
    setConfirmationModalMessage( currentClassIndex < seriesClasses.length
      ? `Part ${currentClassIndex} must be taken before Part ${currentClassIndex + 1} for this ${seriesClasses.length}-class series.\n
        Please confirm if you want to reschedule both Part 1 & Part 2 or remove both classes in this ${seriesClasses.length}-class series.`
      : `Schedule Part ${currentClassIndex} for a date and time later than Part ${currentClassIndex - 1}.\n
        Please confirm if you want to reschedule both Part 1 & Part 2 or remove both classes in this ${seriesClasses.length}-class series.` )
    setContinueBtnText( `Reschedule Part 1 & 2` )
    setCancelBtnText( `Remove 2-Class Series` )
    openConfirmationModal()
  }

  const rescheduleSeries = () => {
    setOpenClass( classDetails?.class_id ?? `` )
    closeConfirmationModal()
    navigate( schedulePath )
  }

  const removeSeries = () => {
    if ( !classDetails || !classDetails.class_series_pk ) return
    const seriesClasses = getSeriesClasses( classDetails.class_series_pk )
    seriesClasses.forEach( ( seriesClass ) => {
      const classDetails = selectedClasses.find( ( _class ) => _class.class_id === seriesClass.class_id )
      const timeSlot = seriesClass.timeslots.find( ( timeSlot ) => selectedClassPks.includes( timeSlot.class_pk ) )

      if ( classDetails !== undefined && timeSlot !== undefined ) {
        removeClassSelection( classDetails, timeSlot )
      }
    })
    closeConfirmationModal()
  }

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="flex gap-4">
        <div>
          <img
            src={classDetails?.image_path}
            width={90}
            height="auto"
            className="mt-2 object-fill"
          />
        </div>
        <div className="w-full text-left text-black flex flex-col gap-1">
          <h3>{classDetails?.class_title}</h3>
          {timeSlot &&
          <p className="font-normal text-xs">{`${new Intl.DateTimeFormat( `en-US`, {
            weekday: `long`,
            month: `short`,
            day: `numeric`,
            timeZone: timezone
          }).format( new Date( timeSlot.timeslot_exact_timestamp ) ) +
            ` - ` +
            new Intl.DateTimeFormat( `en-US`, {
              hour: `numeric`,
              minute: `numeric`,
              hour12: true,
              timeZoneName: `short`,
              timeZone: timezone
            }).format( new Date( timeSlot.timeslot_exact_timestamp ) )}`}</p>
          }
          <div className="pt-2.5 flex justify-between text-secondary text-sm font-bold">
            {timeSlot ?
              <>
                <a onClick={changeDateOrTime}>{`Change Date or Time`}</a>
                {!rescheduling && <a onClick={openRemoveModal}>{`Remove`}</a>}
              </> : <>
                <a onClick={changeDateOrTime}>{`Schedule`}</a>
              </>
            }
          </div>
        </div>
      </div>
      <ConfirmationModal
        title={confirmationModalTitle}
        message={confirmationModalMessage}
        open={showConfirmationModal}
        continueBtnText={continueBtnText}
        cancelBtnText={cancelBtnText}
        handleClose={closeConfirmationModal}
        onConfirmation={series ? rescheduleSeries : removeClass}
        onCancellation={series ? removeSeries : closeConfirmationModal}
      />
    </div>
  )
}

export default ConfirmCard