import {
  SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH,
  INDIVIDUAL_CLASSES_CONFIRM_PATH,
  INDIVIDUAL_CLASSES_THANK_YOU_PATH,
  BUNDLE_SCHEDULE_PATH,
  BUNDLE_CONFIRM_PATH,
  BUNDLE_THANK_YOU_PATH,
  RESCHEDULE_BASE_PATH,
  RESCHEDULE_CONFIRM_PATH
} from 'views/ClassSchedule/utils/constants'
import {
  HerHub,
  IntegratedHistoryForm,
  Login,
  Logout,
  NotFound,
  OneOnOne,
  ScheduleIndividualClasses
} from 'views'
import { HER_HUB_PATH } from 'views/HerHub/utils/constants'
import { Maintenance } from 'views/Maintenance'
import { ForgotPasswordForm } from './views/ForgotPassword'
import { IntakeLookup } from 'components/IntakeLookup'
import IntakeForm from 'components/IntakeForm'
import { IntakeThankYou } from 'views/ThankYou'
import { IntegratedPatientHistoryThankYou } from 'components/IntegratedPatientHistoryThankYou'
import { PostClassSurvey } from 'views/PostClassSurvey'
import { PostClassThankYou } from 'components/PostClassThankYou'
import { TermsAndConditions } from 'views/TermsAndConditions'
import { Individual } from 'views/ClassSchedule/Individual'
import { BundleSelection } from 'views/ClassSchedule/BundleSelection'
import { Bundle } from 'views/ClassSchedule/Bundle'
import { Reschedule } from 'views/ClassSchedule/Reschedule'
import { Confirm } from 'views/ClassSchedule/Confirm'
import { ThankYou } from 'views/ClassSchedule/ThankYou'

export const LOGIN_PAGE_PATH = `/login`
export const FORGOT_PASSWORD_PATH = `/forgot-password`
export const MAINTENANCE_PAGE_PATH = `/maintenance`
export const BUNDLE_SELECTION_BASE_PATH = `/education-classes`
export const INDIVIDUAL_SELECTION_BASE_PATH = `/schedule-your-classes`
export const SCHEDULE_ONE_ON_ONE_APPOINTMENT = `/schedule-appointment`
export const RESCHEDULE_ONE_ON_ONE_APPOINTMENT = `/reschedule-appointment`
export const INTEGRATED_PATIENT_HISTORY_PATH = `/patient-history`
export const INTEGRATED_PATIENT_HISTORY_THANK_YOU_PATH = `/patient-history/thank-you`
export const POST_CLASS_SURVEY_PATH = `/post-class-survey`
export const TERMS_AND_CONDITIONS_PATH = `/terms-and-conditions`

export const INTEGRATED_PATIENT_HISTORY_FEATURE_FLAG = true

const routes = [
  {
    path: LOGIN_PAGE_PATH,
    element: <Login />
  },
  {
    path: MAINTENANCE_PAGE_PATH,
    element: <Maintenance />
  },
  {
    path: SCHEDULE_ONE_ON_ONE_APPOINTMENT.concat( `/*` ),
    element: <OneOnOne />,
    authRoute: true
  },
  {
    path: RESCHEDULE_ONE_ON_ONE_APPOINTMENT,
    element: <OneOnOne />,
    authRoute: true
  },
  {
    path: `/intake/lookup/:from`,
    element: <IntakeLookup />
  },
  {
    path: `/intake/qualify/:from`,
    element: <IntakeForm />
  },
  {
    path: SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH,
    element: <Individual />,
    authRoute: true
  },
  {
    path: SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH.concat( `/*` ),
    element: <ScheduleIndividualClasses />,
    authRoute: true
  },
  {
    path: INDIVIDUAL_CLASSES_CONFIRM_PATH,
    element: <Confirm />,
    authRoute: true
  },
  {
    path: INDIVIDUAL_CLASSES_THANK_YOU_PATH,
    element: <ThankYou />,
    authRoute: true
  },
  {
    path: BUNDLE_SELECTION_BASE_PATH,
    element: <BundleSelection />,
    authRoute: true
  },
  {
    path: BUNDLE_SCHEDULE_PATH.concat( `/*` ),
    element: <Bundle />,
    authRoute: true
  },
  {
    path: BUNDLE_CONFIRM_PATH,
    element: <Confirm />,
    authRoute: true
  },
  {
    path: BUNDLE_THANK_YOU_PATH,
    element: <ThankYou />,
    authRoute: true
  },
  {
    path: `${RESCHEDULE_BASE_PATH}/:classId`,
    element: <Reschedule />,
    authRoute: true
  },
  {
    path: RESCHEDULE_CONFIRM_PATH,
    element: <Confirm />,
    authRoute: true
  },
  {
    path: HER_HUB_PATH,
    element: <HerHub />,
    authRoute: true
  },
  {
    path: TERMS_AND_CONDITIONS_PATH,
    element: <TermsAndConditions />
  },
  {
    path: INTEGRATED_PATIENT_HISTORY_PATH,
    element: <IntegratedHistoryForm />
  },
  {
    path: INTEGRATED_PATIENT_HISTORY_THANK_YOU_PATH,
    element: <IntegratedPatientHistoryThankYou />
  },
  {
    path: POST_CLASS_SURVEY_PATH,
    element: <PostClassSurvey />
  },
  {
    path: POST_CLASS_SURVEY_PATH.concat( `/thank-you` ),
    element: <PostClassThankYou />
  },
  {
    path: `/intake/thank-you`,
    element: <IntakeThankYou />
  },
  {
    path: `/logout`,
    element: <Logout />
  },
  {
    path: FORGOT_PASSWORD_PATH,
    element: <ForgotPasswordForm />
  },
  {
    path: `*`,
    element: <NotFound />
  }
]

export default routes