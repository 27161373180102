import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppointmentsLoadingBox } from 'components/AppointmentsLoadingBox'
import { ClassCard } from 'components/ClassScheduler/ClassCard'
import { ClassFilters } from 'components/ClassFilters'
import SchedulingBottomNav from 'components/SchedulingNavbar/SchedulingBottomNav'
import { SchedulingErrorBlock } from 'components/SchedulingErrorBlock'
import useSelectedBundle from 'hooks/useSelectedBundle'
import useScheduledClasses from 'hooks/useScheduledClasses'
import { useGetBundleDetails } from 'hooks/useGetBundleDetails'
import useClassScheduler from 'stores/useClassSchedulerStore'
import useClassFilters from 'stores/useClassFiltersStore'
import { BUNDLE_CONFIRM_PATH } from 'views/ClassSchedule/utils/constants'
import { BUNDLE_SELECTION_BASE_PATH } from 'routes'
import { ButtonLinkCarat } from 'assets'

function Bundle(): JSX.Element {
  const { selectedBundle, clearBundle } = useSelectedBundle()
  const setPreviouslyScheduledClasses = useClassScheduler( state => state.setPreviouslyScheduledClasses )
  const setCompletedClasses = useClassScheduler( state => state.setCompletedClasses )
  const setRescheduling = useClassScheduler( state => state.setRescheduling )
  const resetScheduler = useClassScheduler( state => state.resetScheduler )
  const resetFilters = useClassFilters( state => state.resetFilters )
  const { bundleDetails, error, loading } = useGetBundleDetails()
  const {scheduledClasses, completedClasses} = useScheduledClasses()

  const navigate = useNavigate()

  const changeBundle = () => {
    clearBundle()
    resetFilters()
    resetScheduler()
    navigate( BUNDLE_SELECTION_BASE_PATH.concat( window.location.search ) )
  }

  const toReview = () => {
    navigate( BUNDLE_CONFIRM_PATH.concat( window.location.search ) )
  }

  useEffect( () => {
    setPreviouslyScheduledClasses( scheduledClasses )
    setCompletedClasses( completedClasses )
    setRescheduling( false )
  }, [ scheduledClasses, completedClasses ] )

  useEffect( () => {
    if ( !selectedBundle || !selectedBundle?.classes?.length ) navigate( BUNDLE_SELECTION_BASE_PATH.concat( window.location.search ) )
  }, [] )

  if ( error ) return <SchedulingErrorBlock message={error} />

  if ( loading ) return <AppointmentsLoadingBox
    message={`Please hold tight while we grab the available class dates and times...`}
  />

  return (
    <div className="flex flex-col relative justify-center items-center gap-5 overflow-hidden px-3">
      <h2 className="text-3xl md:text-4xl font-header mt-5 mb-2 text-center">{`Schedule Your Classes`}</h2>
      <div className="w-full max-w-md px-5 flex flex-col gap-5">
        <div className="flex gap-4 p-4 bg-white-pink">
          <img
            src={bundleDetails?.image_path}
            width={60}
            height={60}
            className="object-cover"
          />
          <div className="text-left flex flex-col gap-2.5">
            <h3>{bundleDetails?.bundle_title}</h3>
            <button
              onClick={changeBundle}
              className="flex items-center justify-start gap-1 text-black p-0"
            >
              <span className="uppercase tracking-widest text-sm font-semibold">{`Change`}</span>
              <img
                src={ButtonLinkCarat}
                width={18}
                height={18}
                className="p-1"
              />
            </button>
          </div>
        </div>
        <p className="text-sm font-semibold text-black text-center">{`Only one class can be scheduled per date`}</p>
        <ClassFilters
          classes={bundleDetails?.classes ?? []}
          filterMapping={[]}
          hideTopicFilters
          bundle
        />
      </div>
      {bundleDetails?.classes &&
        <div className="flex flex-col gap-10 p-5 pb-24 w-full max-w-2xl">
          {bundleDetails?.classes?.map( ( classInfo ) => {
            return <ClassCard
              key={classInfo.class_id}
              classDetails={classInfo}
            />
          })}
        </div>
      }
      <SchedulingBottomNav toReview={toReview} bundleDetails={bundleDetails} />
    </div>
  )
}

export default Bundle