import { ClassConfirmation } from "types/shopScheduleTypes"
import { getSource } from "utils/getSource"

const submitFinalScheduling = async ( classes: ClassConfirmation[], auth: string ) => {
  const response = await fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/schedule-class`, {
    method: `POST`,
    headers: {
      "Authorization": auth
    },
    body: JSON.stringify({
      source: getSource(),
      class_pk: classes.map( ({timeSlot}) => {
        return {
          pk: timeSlot?.class_pk,
          event_instance_id: timeSlot?.event_instance_id,
          scheduled_time: timeSlot?.timeslot_exact_timestamp,
          tz: timeSlot?.time_zone
        }
      })
    })
  })

  return await response.json()
}

export default submitFinalScheduling