import useScheduledClasses from 'hooks/useScheduledClasses'
import { useNavigate, useParams } from 'react-router-dom'
import { SchedulingErrorBlock } from 'components/SchedulingErrorBlock'
import { AppointmentsLoadingBox } from 'components/AppointmentsLoadingBox'
import { useEffect } from 'react'
import {
  Class,
  ClassDescription,
  ClassProduct,
  RescheduleClass,
  RescheduleClassDetails,
  ScheduledClass,
  TimeSlot
} from 'types/shopScheduleTypes'
import useSeriesRescheduleDisplay from '../../../hooks/useSeriesRescheduleDisplay'
import { RESCHEDULE_CONFIRM_PATH } from '../../../views/ClassSchedule/utils/constants'
import { ClassGrid } from '../../ClassScheduler/ClassGrid'
import useClassScheduler from 'stores/useClassSchedulerStore'
import useClassFilters from 'stores/useClassFiltersStore'

function RescheduleClassScheduler(): JSX.Element {
  const selectedClassPks = useClassScheduler( state => state.selectedClassPks )
  const availableClasses = useClassScheduler( state => state.availableClasses )
  const setRescheduling = useClassScheduler( state => state.setRescheduling )
  const setReschedulingClasses = useClassScheduler( state => state.setReschedulingClasses )
  const setOpenClass = useClassScheduler( state => state.setOpenClass )
  const resetScheduler = useClassScheduler( state => state.resetScheduler )

  const resetFilters = useClassFilters( state => state.resetFilters )

  const { scheduledClasses, classProducts, loading, error } = useScheduledClasses()

  const params = useParams<{ classId?: string }>()
  const { seriesClassIds, seriesClasses } = useSeriesRescheduleDisplay()

  const navigate = useNavigate()

  const rescheduleClasses: ScheduledClass[] = seriesClasses.length ? seriesClasses : scheduledClasses.filter( ( _class: ScheduledClass ) => _class.class_id === params.classId )
  const scheduledClassDetails: Class[] = rescheduleClasses.map( ( _class: ScheduledClass ) => {
    const classProduct = classProducts.find( ( _classProduct: ClassProduct ) => _classProduct.sku === _class?.class_sku )

    return {
      class_id: _class?.class_id ?? ``,
      class_sku: _class?.class_sku ?? ``,
      class_pk: _class?.class_pk ?? 0,
      class_title: _class?.class_title ?? ``,
      is_series: _class?.is_series ?? false,
      class_series_pk: _class?.class_series_pk ?? 0,
      class_sequence: _class?.class_sequence,
      image_path: classProduct?.image.url ?? ``,
      small_image_path: classProduct?.small_image.url ?? ``,
      class_magento_id: classProduct?.id ?? 0,
      class_description: classProduct?.short_description?.html ?? ``,
      reviews: 0,
      number_of_reviews: 0,
      class_price: ``,
      patient_id: 0,
      category: ``,
      internal_class_title: ``,
      sort_order: 0,
      class_series: null,
      class_duration: 0,
      is_legacy: false,
      timeslots: []
    }
  })

  const getRescheduleData = (
    scheduledClass: ScheduledClass,
    classDetails: ClassDescription | undefined
  ): RescheduleClass => {
    const newClass = classDetails?.timeslots?.find( ( ts: TimeSlot ) => {
      return selectedClassPks.includes( ts.class_pk )
    })

    const classDetail: RescheduleClassDetails = { // Note: these values should never be undefined, but TS
      class_pk: scheduledClass.class_pk,
      class_id: scheduledClass.class_id,
      event_instance_id: scheduledClass?.event_instance_id ?? ``,
      scheduled_time: scheduledClass?.timeslot_exact_timestamp ?? ``,
      tz: scheduledClass?.time_zone ?? ``,
      scheduled_event_instance_id: scheduledClass?.event_instance_id ?? ``,
      scheduled_event_attendance_id: scheduledClass?.event_attendance_id ?? ``
    }

    return {
      classDetail,
      newClass
    }
  }

  const toReview = () => {
    const reschedulingClasses: RescheduleClass[] = rescheduleClasses.map( ( _class: ScheduledClass ) => {
      const classDescription = availableClasses.find( ( _classDescription: ClassDescription ) => _classDescription.class_id === _class.class_id )

      return getRescheduleData( _class, classDescription )
    })
    setReschedulingClasses( reschedulingClasses )
    navigate( `${RESCHEDULE_CONFIRM_PATH}${window.location.search}` )
  }

  useEffect( () => {
    if ( !params.classId || seriesClassIds === null ) return
    resetScheduler()
    resetFilters()
    setOpenClass( seriesClassIds?.length ? seriesClassIds[0] : params.classId )
    setRescheduling( true )
  }, [ params.classId, seriesClassIds ] )

  if ( error || !params.classId || !scheduledClassDetails ) return (
    <SchedulingErrorBlock
      message={`You may not be eligible for additional classes at this time`}
      backButton
    />
  )

  if ( loading || seriesClassIds === null ) return (
    <div className="my-4">
      <AppointmentsLoadingBox message="Please hold tight while we get the availability for this class..." />
    </div>
  )

  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-heading font-header text-black text-center">{`Reschedule this class`}</h2>
      <p
        className="text-sm text-black text-center px-2"
      >{`All classes are online and taught live by certified instructors!`}</p>
      <div className="flex flex-col gap-10 p-5 pb-10 items-center">
        <ClassGrid classes={scheduledClassDetails} />
        <button
          className="standard-button"
          disabled={selectedClassPks.length !== rescheduleClasses.length}
          onClick={toReview}
        >{`Review`}</button>
      </div>
    </div>
  )

}

export default RescheduleClassScheduler
