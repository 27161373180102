import { useKameleoonStore } from 'stores/kameleoonStore'
import RescheduleDisplay from 'views/Reschedule/RescheduleDisplay'
import { RescheduleClassScheduler } from '../../../components/ClassSchedule/Reschedule'

const Reschedule = () => {
  const uxUpdateFeature = useKameleoonStore( store => store.uxUpdateFeature )

  if ( uxUpdateFeature ) {
    return <RescheduleClassScheduler />
  }

  return <RescheduleDisplay />
}

export default Reschedule