export const SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH = `/schedule-your-classes`
export const INDIVIDUAL_CLASSES_CONFIRM_PATH = `${SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH}/confirm`
export const INDIVIDUAL_CLASSES_THANK_YOU_PATH = `${SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH}/thank-you`

export const SCHEDULE_BUNDLE_BASE_PATH = `/schedule-your-bundle`
export const BUNDLE_SCHEDULE_PATH = `${SCHEDULE_BUNDLE_BASE_PATH}/schedule`
export const BUNDLE_CONFIRM_PATH = `${SCHEDULE_BUNDLE_BASE_PATH}/confirm`
export const BUNDLE_THANK_YOU_PATH = `${SCHEDULE_BUNDLE_BASE_PATH}/thank-you`

export const RESCHEDULE_BASE_PATH = `/reschedule`
export const RESCHEDULE_CONFIRM_PATH = `${RESCHEDULE_BASE_PATH}/confirm`