module.exports = {
  gray: {
    white: `#f9f9f9`,
    light: `#d3d3d3`,
    dark: `#515151`
  },
  pink: {
    white: `#fef8f8`,
    "1": `#faeae7`,
    "2": `#f5d3ce`,
    "3": `#f9b9b7`,
    "4": `#f48b91`
  },
  white: {
    DEFAULT: `#ffffff`,
    pink: `#fff6f4`
  },
  black: `#333333`,
  error: `#b30000`,
  primary: `#f3757e`,
  secondary: `#00464a`
}
