import { ConfirmationModal } from 'components/ConfirmationModal'
import useConfirmationModal from 'hooks/useConfirmationModal'
import useClassScheduler from 'stores/useClassSchedulerStore'
import { useState } from 'react'
import { Class, ClassDescription, TimeSlot } from 'types/shopScheduleTypes'
import { timeSlotFormatter } from 'utils/time'
import { ClassSelectedIcon, DisabledPlusIcon, PlusIcon } from 'assets'
import { ThemedModal } from 'components/ThemedModal'

type SchedulerTimeSlotProps = {
  timeSlot: TimeSlot;
  classDetails: Class;
  selectedTimeSlot: TimeSlot | undefined;
  setSelectedClassPk: ( _classPk: number | undefined ) => void;
}
const SchedulerTimeSlot = ({
  timeSlot,
  classDetails,
  selectedTimeSlot,
  setSelectedClassPk
}: SchedulerTimeSlotProps ) => {
  const availableClasses = useClassScheduler( state => state.availableClasses )
  const selectedClassPks = useClassScheduler( state => state.selectedClassPks )
  const selectedClasses = useClassScheduler( state => state.selectedClasses )
  const selectedDays = useClassScheduler( state => state.selectedDays )
  const completedClasses = useClassScheduler( state => state.completedClasses )
  const classLimit = useClassScheduler( state => state.classLimit )
  const timezone = useClassScheduler( state => state.timezone )
  const rescheduling = useClassScheduler( state => state.rescheduling )
  const addSelectedClassPk = useClassScheduler( state => state.addSelectedClassPk )
  const removeSelectedClassPk = useClassScheduler( state => state.removeSelectedClassPk )
  const setSelectedDays = useClassScheduler( state => state.setSelectedDays )
  const addSelectedClass = useClassScheduler( state => state.addSelectedClass )
  const setRemoveSeriesClass = useClassScheduler( state => state.setRemoveSeriesClass )
  const removeClassSelection = useClassScheduler( state => state.removeClassSelection )

  const [ confirmationModalMessage, setConfirmationModalMessage ] = useState<string>( `` )
  const {
    showConfirmationModal,
    continueBtnText,
    cancelBtnText,
    openConfirmationModal,
    closeConfirmationModal,
    setContinueBtnText,
    setCancelBtnText
  } = useConfirmationModal()

  const [ modalMessage, setModalMessage ] = useState<string>( `Your maximum course availability is ${classLimit} classes at this time` )
  const [ openModal, setOpenModal ] = useState<boolean>( false )
  const closeModal = () => {
    setModalMessage( `Your maximum course availability is ${classLimit} classes at this time` )
    setOpenModal( false )
  }

  const classDescription = availableClasses.find( ( _class: ClassDescription ) => _class.class_id === classDetails.class_id )

  const getSeriesClasses = ( _classSeriesPk: number ): ClassDescription[] => {
    return availableClasses.filter( ( availableClass: ClassDescription ) => {
      return availableClass.class_series_pk === _classSeriesPk
    }).filter( ( availableSeriesClass: ClassDescription ) => {
      return completedClasses?.find( ( completedClass ) => {
        return completedClass.class_id === availableSeriesClass.class_id
      }) === undefined
    })
      .sort( ( a: ClassDescription, b: ClassDescription ) => {
        return a.class_sequence - b.class_sequence
      })
  }

  const seriesClassRemove = (): void => {
    setRemoveSeriesClass( classDetails.class_series_pk )
    closeConfirmationModal()
  }

  const seriesTimeSlotAlreadySelected = (): boolean => {
    if ( classDetails.is_series && classDetails.class_series_pk ) {
      const seriesClasses = getSeriesClasses( classDetails.class_series_pk )
      const currentClassIndex = seriesClasses.findIndex( ( seriesClass: ClassDescription ) => {
        return seriesClass.class_id === classDetails.class_id
      })
      let nextClassInSeries = ``
      let lastClassInSeries = false
      for ( let i = 0; i < seriesClasses.length; i++ ) {
        const selectedClassIds = selectedClasses.map( ( selectedClass: Class ) => {
          return selectedClass.class_id
        })

        if ( !selectedClassIds.includes( seriesClasses[i].class_id ) ) {
          nextClassInSeries = seriesClasses[i].class_id
          break
        }
        if ( i === seriesClasses.length - 1 && seriesClasses[i].class_id === classDetails.class_id ) {
          lastClassInSeries = true
        }
      }
      if ( selectedClassPks && !nextClassInSeries && !rescheduling ) {
        const message = lastClassInSeries
          ? `Part ${currentClassIndex + 1} must be scheduled with Part ${currentClassIndex} for this ${seriesClasses.length}-class series.`
          : `Part ${currentClassIndex + 1} must be taken before Part ${currentClassIndex + 2} for this ${seriesClasses.length}-class series.`
        setConfirmationModalMessage( `${message} \n\n Please confirm if you want to reschedule Part ${currentClassIndex + 1} or remove both classes in this ${seriesClasses.length}-class series.` )
        setContinueBtnText( `Reschedule Part ${currentClassIndex + 1}` )
        setCancelBtnText( `Remove ${seriesClasses.length}-Class Series` )
        openConfirmationModal()

        return true
      }
    }

    return false
  }

  const isSeriesSchedulingConflict = ( timeSlot: TimeSlot ): boolean => {
    if ( classDetails.is_series && classDetails.class_series_pk ) {
      const availableSeriesClasses = availableClasses.filter( ( availableClass: ClassDescription ) => {
        return availableClass.class_series_pk === classDetails.class_series_pk
      })

      const seriesTimeSlots: { [key: number]: TimeSlot } = {}
      availableSeriesClasses.forEach( ( availableSeriesClass ) => {
        const timeSlot: TimeSlot = availableSeriesClass.timeslots.find( ( ts: TimeSlot ) => {
          return selectedClassPks.includes( ts.class_pk )
        })

        if ( timeSlot ) seriesTimeSlots[availableSeriesClass.class_sequence] = timeSlot
      })

      for ( const [ classSequence, classTimeSlot ] of Object.entries( seriesTimeSlots ) ) {
        if ( classDetails.class_sequence < classSequence ) {
          const tsDate = new Date( timeSlot.scheduled_date )
          const selectedDate = new Date( classTimeSlot.scheduled_date )
          if ( tsDate.getTime() >= selectedDate.getTime() ) {
            return true
          }
        } else if ( classDetails.class_sequence > classSequence ) {
          const tsDate = new Date( timeSlot.scheduled_date )
          const selectedDate = new Date( classTimeSlot.scheduled_date )
          if ( tsDate.getTime() <= selectedDate.getTime() ) {
            return true
          }
        }
      }
    }

    return false
  }

  const showConflictAlert = () => {
    if ( classDetails.is_series && classDetails.class_series_pk && isSeriesSchedulingConflict( timeSlot ) ) {
      const seriesClasses = getSeriesClasses( classDetails.class_series_pk )
      const currentClassIndex = seriesClasses.findIndex( ( _class: ClassDescription ) => _class.class_id === classDetails.class_id ) + 1
      const timing = currentClassIndex < seriesClasses.length ? `before` : `after`
      const otherClassIndex = timing === `before` ? currentClassIndex + 1 : currentClassIndex - 1
      setModalMessage( `Hey Mama! This class is part of a ${seriesClasses.length}-class series. Part ${currentClassIndex} must be taken ${timing} Part ${otherClassIndex}.` )
    } else {
      setModalMessage( `Only one class can be scheduled per date - please reschedule another class to select this date.` )
    }
    setOpenModal( true )
  }

  const isDateAvailable = (): boolean => {
    const sameDate = selectedTimeSlot?.scheduled_date === timeSlot.scheduled_date
    if ( isSeriesSchedulingConflict( timeSlot ) && !sameDate ) return false

    return !selectedDays.includes( timeSlot.scheduled_date ) || timeSlot.class_pk === selectedTimeSlot?.class_pk || selectedClassPks.includes( timeSlot.class_pk ) || sameDate
  }

  const canAddClass = (): boolean => {
    if ( selectedDays.length >= classLimit ) {
      const changingTimeSlotForCurrentClass = ( classDescription?.timeslots ?? [] ).filter( ( timeSlot: TimeSlot ) => {
        return selectedClassPks.includes( timeSlot.class_pk )
      }).length > 0

      return changingTimeSlotForCurrentClass
    }

    return true
  }

  const addClass = () => {
    if ( selectedTimeSlot ) removeSelectedClassPk( selectedTimeSlot?.class_pk )
    const newSelectedDays = selectedDays.filter( ( day: string ) => {
      return day !== selectedTimeSlot?.scheduled_date
    })
    setSelectedClassPk( timeSlot.class_pk )
    addSelectedClassPk( timeSlot.class_pk )
    setSelectedDays( [ ...newSelectedDays, timeSlot.scheduled_date ] )
    addSelectedClass( classDetails )
  }

  const selectTimeSlot = () => {
    if ( !isDateAvailable() ) {
      showConflictAlert()

      return
    }

    if ( selectedTimeSlot?.class_pk && selectedTimeSlot?.class_pk === timeSlot.class_pk ) {
      setSelectedClassPk( undefined )
      removeClassSelection( classDetails, timeSlot )
    } else {
      if ( canAddClass() ) {
        addClass()
      } else {
        setOpenModal( true )
      }
    }
  }

  const handleTimeSlotSelection = () => {
    if ( seriesTimeSlotAlreadySelected() ) return
    selectTimeSlot()
  }

  const rescheduleSeriesClass = () => {
    selectTimeSlot()
    closeConfirmationModal()
  }

  return (
    <div>
      <div
        key={timeSlot.class_pk}
        className={`flex gap-2.5 p-2.5 rounded-xl ${selectedTimeSlot?.class_pk === timeSlot.class_pk && `bg-secondary text-white-pink`}`}
        onClick={handleTimeSlotSelection}
      >
        <img
          src={
            selectedTimeSlot?.class_pk === timeSlot.class_pk ? ClassSelectedIcon
              : isDateAvailable() ? PlusIcon : DisabledPlusIcon
          }
          alt={`schedule class on ${timeSlot.scheduled_date_pretty} at ${timeSlot.begin_time_pretty}`}
          height={24}
          width={24}
        />
        <p>{`${timeSlotFormatter( timezone ).format( new Date( timeSlot.timeslot_exact_timestamp ) )}`}</p>
      </div>
      <ConfirmationModal
        title="Are you sure?"
        message={confirmationModalMessage}
        continueBtnText={continueBtnText}
        cancelBtnText={cancelBtnText}
        open={showConfirmationModal}
        handleClose={closeConfirmationModal}
        onConfirmation={rescheduleSeriesClass}
        onCancellation={seriesClassRemove}
      />
      <ThemedModal
        message={modalMessage}
        open={openModal}
        handleClose={closeModal}
      />
    </div>
  )
}

export default SchedulerTimeSlot