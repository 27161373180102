import useClassScheduler from 'stores/useClassSchedulerStore'
import { Class } from 'types/shopScheduleTypes'
import { ClassCard } from '../ClassCard'

type ClassGridProps = {
  classes: Class[] | null
}

function ClassGrid({ classes }: ClassGridProps ) : JSX.Element | null {
  const classLimit = useClassScheduler( state => state.classLimit )
  const rescheduling = useClassScheduler( state => state.rescheduling )
  const filteredClasses = classes?.filter( ( classDetails: Class ) => {
    return Boolean( classDetails.class_sku )
  })

  return (
    <div className="grid grid-cols-1 gap-10 w-full max-w-2xl">
      {!rescheduling &&
        <div className="text-xs font-semibold">
          <p>{`Schedule up to ${classLimit} classes`}</p>
          <p>{`Only one class can be scheduled per date`}</p>
        </div>
      }
      {filteredClasses && filteredClasses.length > 0 ?
        filteredClasses.map( ( classDetails: Class ) => {
          return (
            <ClassCard
              key={classDetails.class_id}
              classDetails={classDetails}
            />
          )
        })
        : <p className="text-error font-semibold">{`No matching classes available - please change your filters to see available classes`}</p>
      }
    </div>
  )
}

export default ClassGrid