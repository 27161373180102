import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { scrollToTop } from 'utils'
import { reportToSentry } from 'utils/reportToSentry'
import submitFinalScheduling from 'utils/submitFinalScheduling'
import { AppointmentsLoadingBox } from 'components/AppointmentsLoadingBox'
import TermsChecker from 'components/TermsChecker'
import ConfirmCard from './ConfirmCard'
import useAuthStore from 'stores/auth'
import useHerHubStore from 'stores/herHub'
import useClassScheduler from 'stores/useClassSchedulerStore'
import useClassFilters from 'stores/useClassFiltersStore'
import useSelectedBundle from 'hooks/useSelectedBundle'
import postCancelClass from 'apis/postCancelClass'
import { Class, ClassConfirmation, ClassDescription, RescheduleClass, TimeSlot } from 'types/shopScheduleTypes'
import {
  BUNDLE_SCHEDULE_PATH,
  BUNDLE_THANK_YOU_PATH,
  INDIVIDUAL_CLASSES_THANK_YOU_PATH,
  RESCHEDULE_BASE_PATH
} from 'views/ClassSchedule/utils/constants'
import { INDIVIDUAL_SELECTION_BASE_PATH } from 'routes'
import { useKameleoonStore } from 'stores/kameleoonStore'
import { useTrackConversion } from 'hooks/useTrackConversion'

const Confirm = (): JSX.Element => {
  const availableClasses = useClassScheduler( state => state.availableClasses )
  const selectedClasses = useClassScheduler( state => state.selectedClasses )
  const selectedClassPks = useClassScheduler( state => state.selectedClassPks )
  const rescheduling = useClassScheduler( state => state.rescheduling )
  const reschedulingClasses = useClassScheduler( state => state.reschedulingClasses )
  const resetScheduler = useClassScheduler( state => state.resetScheduler )
  const resetFilters = useClassFilters( state => state.resetFilters )

  const [ loading, setLoading ] = useState<boolean>( false )
  const [ schedulingError, setSchedulingError ] = useState<string>( `` )
  const [ isTermChecked, setIsTermChecked ] = useState<boolean>( false )
  const [ termsError, setTermsError ] = useState<string>( `` )

  const { selectedBundle } = useSelectedBundle()
  const { addRecentReschedule, hideClassFromNotifications } = useHerHubStore()
  const navigate = useNavigate()

  const { buildAuthorizer } = useAuthStore()
  const authHeader = buildAuthorizer()

  const visitorCode = useKameleoonStore( state => state.visitorCode ) ?? `unknown`
  const { trackClassScheduleConversion } = useTrackConversion( visitorCode )

  const scheduleClasses = async ( _classes: ClassConfirmation[] ) => {
    const response = await submitFinalScheduling( _classes, authHeader )
      .catch( ( error: Error ) => {
        setLoading( false )
        reportToSentry( new Error( `Shop and Schedule: An error occurred submitting a patients final scheduling`, {
          cause: error
        }), {
          authHeader,
          classes: JSON.stringify( _classes )
        })

        return setSchedulingError( `Oops... We encountered an error scheduling your classes. Please try again or contact customer service at 888-732-3979 if the issue persists.` )
      })

    trackClassScheduleConversion({
      classList: _classes.map( ({ classDetails }) => classDetails.class_sku ).join( `,` ),
      classScheduleType: rescheduling ? `Reschedule` : selectedBundle ? `Bundle` : `Individual`
    })

    return response
  }

  const cancelRescheduledClasses = async ( reschedulingClasses: RescheduleClass[] ) => {
    await Promise.all(
      reschedulingClasses.map( async ( _rescheduleClass: RescheduleClass ) => {
        const cancelResponse = await postCancelClass( _rescheduleClass.classDetail.event_instance_id, _rescheduleClass.classDetail.scheduled_event_attendance_id )

        if ( !( ( await cancelResponse?.json() )?.message === `Submitted` ) ) {
          reportToSentry( new Error( `Reschedule Class - failed to cancel class`, {
            cause: JSON.stringify( cancelResponse )
          }) )

          return setSchedulingError( `Oops... We encountered an exception canceling your previous class scheduling.` )
        }
      })
    )
  }

  const handleConfirmation = async () => {
    if ( !isTermChecked ) return setTermsError( `Please accept terms and conditions` )

    setLoading( true )
    const classes = classConfirmations.filter( ({ timeSlot }) => timeSlot !== undefined )
    const schedulingResponse = await scheduleClasses( classes )
    if ( rescheduling ) {
      await cancelRescheduledClasses( reschedulingClasses )
    }
    setLoading( false )

    if ( schedulingResponse?.data?.success && schedulingResponse?.meta?.status === `Accepted` ) {
      classes.forEach( ({ classDetails, timeSlot }) => {
        if ( !timeSlot ) return
        hideClassFromNotifications( timeSlot.class_pk )
        addRecentReschedule({
          class_sku: classDetails.class_sku,
          class_title: classDetails.class_title,
          ...timeSlot as any
        })
      })

      resetFilters()
      resetScheduler()

      sessionStorage.setItem( `has_scheduled`, `true` )

      if ( window.location !== window?.parent?.location ) window?.parent?.postMessage( `Schedule Success`, `*` )
      else navigate( `${schedulingBundle() ? BUNDLE_THANK_YOU_PATH : INDIVIDUAL_CLASSES_THANK_YOU_PATH}${window.location.search}` )

      return scrollToTop()
    }

    setSchedulingError( `Oops... We encountered an error scheduling your classes. Please try again or contact customer service at 888-732-3979 if the issue persists.` )
    reportToSentry( new Error( `Shop and Schedule: An error occurred submitting a patients final scheduling` ), {
      authHeader,
      classes: JSON.stringify( classes )
    })
  }

  const schedulingBundle = (): boolean => {
    return Boolean( selectedBundle?.classes?.length )
  }

  let schedulePath = `${schedulingBundle() ? BUNDLE_SCHEDULE_PATH : INDIVIDUAL_SELECTION_BASE_PATH}${window.location.search}`
  if ( rescheduling ) {
    const classId = reschedulingClasses[0].classDetail.class_id
    schedulePath = `${RESCHEDULE_BASE_PATH}/${classId}${window.location.search}`
  }

  const getClassConfirmation = ( classPk: number | undefined, classDescription: ClassDescription, classes: Class[] ): ClassConfirmation | undefined => {
    const classDetails = classes.find( ( classItem: Class ) => {
      return classItem.class_id === classDescription.class_id
    })
    const timeSlot: TimeSlot | undefined = classDescription?.timeslots.find( ( timeslot: TimeSlot ) => {
      return timeslot.class_pk === classPk
    })
    if ( !classDetails ) return

    return {
      classDetails,
      timeSlot
    }
  }

  const getClassConfirmations = ( schedulingBundle: boolean, classDescriptions: ClassDescription[], classPks: number[] ): ClassConfirmation[] => {
    if ( schedulingBundle ) {
      return classDescriptions?.map( ( classDescription: ClassDescription ) => {
        const classPk = selectedClassPks.find( ( classPk: number ) => {
          return classDescription.timeslots.find( ( timeslot: TimeSlot ) => {
            return timeslot.class_pk === classPk
          })
        })

        return getClassConfirmation( classPk, classDescription, selectedBundle?.classes ?? [] )
      }).filter( ( confirmation: ClassConfirmation | undefined ) => confirmation !== undefined ) as ClassConfirmation[]
    } else {
      return classPks.map( ( classPk: number ) => {
        const classDescription = availableClasses.find( ( classItem: ClassDescription ) => {
          return classItem.timeslots.find( ( timeslot: TimeSlot ) => {
            return timeslot.class_pk === classPk
          })
        })
        if ( !classDescription ) return

        return getClassConfirmation( classPk, classDescription, selectedClasses )
      }).filter( ( confirmation: ClassConfirmation | undefined ) => confirmation !== undefined ) as ClassConfirmation[]
    }
  }

  const classConfirmations = getClassConfirmations( schedulingBundle(), availableClasses, selectedClassPks )

  if ( loading ) return <AppointmentsLoadingBox message={`Please hold tight while we schedule your classes...`} />

  if ( selectedClassPks?.length === 0 ) {
    navigate( schedulePath )
  }

  return (
    <div className="flex flex-col relative justify-center items-center overflow-hidden max-w-lg mx-auto px-5 pb-5">
      <div className="flex flex-col gap-10 pb-10">
        <h2 className="font-header text-heading text-center font-normal text-black">{`Please Review & Confirm`}</h2>
        <div className="flex flex-col justify-center items-center gap-8 w-full">
          {
            classConfirmations.map( ({ classDetails, timeSlot }) => {
              return <ConfirmCard
                key={classDetails?.class_id}
                classDetails={classDetails}
                timeSlot={timeSlot}
                schedulePath={schedulePath}
              />
            })
          }
        </div>
        <div className="flex flex-col gap-2.5">
          {
            !selectedBundle?.classes?.length && classConfirmations?.length < 6 &&
            <p
              onClick={() => {
                return navigate( `${INDIVIDUAL_SELECTION_BASE_PATH}${window.location.search}` )
              }}
              className="a text-center"
            >
              {`Add More Classes`}
            </p>
          }
          <TermsChecker
            name="authorize_aeroflow"
            type="checkbox"
            value={isTermChecked}
            containerClassname={`${!termsError && `mb-7`}`}
            onClick={() => {
              setTermsError( `` )
              setIsTermChecked( !isTermChecked )
            }}
          />
          {termsError && <p className="text-error text-center mt-2 mb-7">{termsError}</p>}
        </div>
        <div className="w-full">
          <button
            onClick={handleConfirmation}
            className="primary-button w-full text-sm font-semibold tracking-widest"
          >
            {`Confirm`}
          </button>
          {schedulingError &&
            <p className="text-error my-5 font-light text-center text-base md:text-lg">{schedulingError}</p>}
        </div>
      </div>
    </div>
  )
}

export default Confirm