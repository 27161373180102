import styled from 'styled-components'
import DayPicker from 'react-day-picker'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const tailwindColors = require( `config/tailwindColors` )

export const StyledDayPicker = styled( DayPicker ).attrs({
  className:
    `w-full rounded-lg md:px-12 min-w-fit shadow-md`
})`
  min-width: fit-content;
  margin: 0 auto 2rem;
  font-size: 20px;
  .DayPicker-NavBar {
    position: relative;
    .DayPicker-NavButton {
      position: absolute;
      top: 1rem;
    }
    .DayPicker-NavButton--prev {
      left: 1rem;
    }
    .DayPicker-NavButton--next {
      right: 1rem;
    }
  }

  .DayPicker-Month {
    margin: 16px 16px 0;
    .DayPicker-Caption {
      text-align: center;
      margin-bottom: 10px;
      padding: 0 10px;
      & > div {
        visibility: hidden;
      }
    }
    .DayPicker-Weekday {
      display: table-cell;
      padding: 8px;
      color: #8b9898;
      text-align: center;
      font-size: 18px;
    }
  }

  .DayPicker-Day,
  .DayPicker-WeekNumber {
    padding: 14px;
    cursor: default;
    color: #d2d2d2;
    border: none;
    border-radius: 0;
    width: 45px;
    height: 45px;
    background: none;
    &:hover {
      background: none;
    }
  }
  .DayPicker-Day--today {
    color: rgba(0, 0, 0, 0.18);
    .date-number-wrapper {
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.18);
    }
  }
  .DayPicker-Day .date-number-wrapper,
  .DayPicker-WeekNumber .date-number-wrapper {
    width: 45px;
    height: 45px;
    display: flex;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background: none;
  }
  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    pointer-events: none;
    cursor: default;
  }
  &:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
    pointer-events: none;
    background-color: transparent;
    cursor: default;
  }
  &:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    pointer-events: none;
    background-color: transparent;
    cursor: default;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--birthday)
  .date-number-wrapper {
    transition: all 0.25s ease-in-out; 
    color: black;
    &:hover {
      transform: scale( 1.20 );
      background: white;
      color: #00464A;
      cursor: pointer;
    }
    border-radius: 50%;
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: none;
  }
  .DayPicker-Day--birthday
  .date-number-wrapper {
    background: ${tailwindColors.secondary};
    color: white;
    border-color: ${tailwindColors.secondary};
    border-radius: 50%;
  }

  .DayPicker-Day--birthday {
    border-radius: 50%;
    border-color: ${tailwindColors.secondary};
    box-shadow: 0px 0px 0px 3px ${tailwindColors.secondary} inset;
  }

  @media screen and (max-width: 600px) {
    .DayPicker-Day,
    .DayPicker-WeekNumber {
      padding: 0;
    }
    .DayPicker-Day {
      .date-number-wrapper {
        transform: scale( 0.7 );
      }
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--birthday)
    .date-number-wrapper:hover {
      transform: scale( 0.90 );
    }
  }
`
export const StyledDayPickerV2 = styled( DayPicker )`
  .DayPicker-Day {
    .date-number-wrapper {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      .date-number {
        font-size: 14px;
      }
    }

    background: none;
    padding: 0.4em;
  }

  .DayPicker-Day--today {
    .date-number-wrapper {
      background: ${tailwindColors.white.pink};
      border-radius: 0;
    }
  }

  .DayPicker-Day--selected {
    .date-number-wrapper {
      background-color: ${tailwindColors.secondary};
      color: ${tailwindColors.white.pink};
    }
    
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background: none;
    }
  }

  .DayPicker-Day--hasUpcomingTimeSlots {
    .date-number-wrapper {
      border: 1px solid ${tailwindColors.black};
      border-radius: 100%;
    }
  }
`

export const AppointmentButton = styled.button.attrs({
  className: `my-2 border-none py-4 mx-2`
})`
  background-color: ${props => {
    return props.active
      ? `${tailwindColors.secondary}`
      : `white`
  }};
  min-width: fit-content;
  border-radius: 10px;
  border: 1px solid #ece1e2;
  box-sizing: border-box;
  transition: all 0.3s;
  color: ${props => {
    return props.active
      ? `white`
      : `black`
  }};
  .duration-text {
    transition: all 0.3s;
    color: ${props => {
    return props.active
      ? `white`
      : `#c2c2c2`
  }};
  }
  &:active {
    transform: scale(0.98);
  }
  &:hover {
    cursor: pointer;
    color: white;
    background-color: ${tailwindColors.secondary};
    .duration-text {
      color: white;
    }
  }
`
