import useClassScheduler from "stores/useClassSchedulerStore"
import { useEffect } from "react"
import { Bundle } from "types/shopScheduleTypes"

type SchedulingBottomNavProps = {
    toReview: () => void;
    bundleDetails?: Bundle | null;
}
const SchedulingBottomNav = ({ toReview, bundleDetails }: SchedulingBottomNavProps ): JSX.Element => {
  const selectedClassPks= useClassScheduler( state => state.selectedClassPks )
  const classLimit = useClassScheduler( state => state.classLimit )

  useEffect( () => {
    const fnElement = document.getElementById( `fn-chat-iframe-container` )
    if ( fnElement ) fnElement.style.display = `none`

    return () => {
      const fnElement = document.getElementById( `fn-chat-iframe-container` )
      if ( fnElement ) fnElement.style.display = `block`
    }
  }, [] )


  if ( selectedClassPks.length > 0 ) return (
    <div className="fixed bottom-0 p-5 bg-white-pink w-full z-above-chat">
      <div className="flex gap-6 justify-between items-center max-w-2xl mx-auto">
        <div>
          {bundleDetails ? (
            <p className="text-sm font-semibold">{`Classes Scheduled: ${selectedClassPks.length}/${bundleDetails.classes?.length}`}</p>
          ) : (
            <>
              <p className="text-sm font-semibold">{`Classes Scheduled: ${selectedClassPks.length}`}</p>
              <p className="text-xs">{`Choose up to ${classLimit} classes`}</p>
            </>
          )}
        </div>
        <button
          onClick={toReview}
          className="primary-button"
        >
          {`Review`}
        </button>
      </div>
    </div>
  )

  return <></>
}

export default SchedulingBottomNav