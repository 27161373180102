import { create } from 'zustand'
import { getCurrentTimeZoneString } from 'utils/time'
import { Class, ClassDescription, RescheduleClass, ScheduledClass, TimeSlot } from 'types/shopScheduleTypes'

export type ClassSchedulerState = {
  availableClasses: ClassDescription[];
  selectedClassPks: number[];
  selectedClasses: Class[];
  timezone: string;
  selectedDays: string[];
  previouslyScheduledClasses: ScheduledClass[];
  completedClasses: ScheduledClass[];
  classLimit: number;
  openClass: string;
  rescheduling: boolean;
  reschedulingClasses: RescheduleClass[];
  removeSeriesClass: number | null;
  addAvailableClass: ( _class: ClassDescription ) => void;
  setAvailableClasses: ( _classes: ClassDescription[] ) => void;
  getClassTimeSlots: ( _classId: string ) => TimeSlot[];
  addSelectedClassPk: ( _classPk: number ) => void;
  removeSelectedClassPk: ( _classPk: number ) => void;
  clearSelectedClassPks: () => void;
  addSelectedClass: ( _class: Class ) => void;
  removeSelectedClass: ( _class: Class ) => void;
  clearSelectedClasses: () => void;
  setTimeZone: ( _timezone: string ) => void;
  setSelectedDays: ( _days: string[] ) => void;
  setPreviouslyScheduledClasses: ( _classes: ScheduledClass[] ) => void;
  setCompletedClasses: ( _classes: ScheduledClass[] ) => void;
  setClassLimit: ( _limit: number ) => void;
  resetScheduler: () => void;
  removeClassSelection: ( _classDetails: Class, _timeSlot: TimeSlot ) => void;
  setOpenClass: ( _classId: string ) => void;
  setRescheduling: ( _rescheduling: boolean ) => void;
  setReschedulingClasses: ( _reschedulingClasses: RescheduleClass[] ) => void;
  setRemoveSeriesClass: ( _series_class_pk: number | null ) => void;
}

export const CLASS_LIMIT = 6

const useClassSchedulerStore = create<ClassSchedulerState>( ( set, get ) => {
  return {
    availableClasses: [],
    selectedClassPks: [],
    selectedClasses: [],
    timezone: getCurrentTimeZoneString(),
    selectedDays: [],
    previouslyScheduledClasses: [],
    completedClasses: [],
    classLimit: CLASS_LIMIT,
    openClass: ``,
    rescheduling: false,
    reschedulingClasses: [],
    removeSeriesClass: null,
    addAvailableClass: ( _class: ClassDescription ) => {
      const index = get().availableClasses.findIndex( ( c: ClassDescription ) => c.class_id === _class.class_id )
      if ( index !== -1 ) {
        const newAvailableClasses = [ ...get().availableClasses ]
        newAvailableClasses[ index ] = _class
        set({
          availableClasses: newAvailableClasses
        })
      } else {
        set({
          availableClasses: [ ...get().availableClasses, _class ]
        })
      }
    },
    setAvailableClasses: ( classes: ClassDescription[] ) => {
      set({
        availableClasses: classes
      })
    },
    getClassTimeSlots: ( classId: string ) => {
      const classObjs = get().availableClasses.filter( ( c: ClassDescription ) => c.class_id === classId )

      return classObjs?.map( obj => obj.timeslots ).flat()
        .sort( ( a: TimeSlot, b: TimeSlot ) => {
          return new Date( a.timeslot_exact_timestamp ) < new Date( b.timeslot_exact_timestamp ) ? -1 : new Date( a.timeslot_exact_timestamp ) > new Date( b.timeslot_exact_timestamp ) ? 1 : 0
        }) ?? []
    },
    addSelectedClassPk: ( classPk: number ) => {
      set({
        selectedClassPks: [ ...get().selectedClassPks, classPk ]
      })
    },
    removeSelectedClassPk: ( classPk: number ) => {
      set({
        selectedClassPks: get().selectedClassPks.filter( ( pk: number ) => {
          return pk !== classPk
        })
      })
    },
    clearSelectedClassPks: () => {
      set({
        selectedClassPks: []
      })
    },
    addSelectedClass: ( _class: Class ) => {
      set({
        selectedClasses: [ ...get().selectedClasses, _class ]
      })
    },
    removeSelectedClass: ( _class: Class ) => {
      set({
        selectedClasses: get().selectedClasses.filter( ( c: Class ) => c.class_id !== _class.class_id )
      })
    },
    clearSelectedClasses: () => {
      set({
        selectedClasses: []
      })
    },
    setTimeZone: ( timezone: string ) => {
      set({
        timezone: timezone
      })
    },
    setSelectedDays: ( days: string[] ) => {
      set({
        selectedDays: days
      })
    },
    setPreviouslyScheduledClasses: ( classes: ScheduledClass[] ) => {
      set({
        previouslyScheduledClasses: classes
      })
    },
    setCompletedClasses: ( classes: ScheduledClass[] ) => {
      set({
        completedClasses: classes
      })
    },
    setClassLimit: ( limit: number ) => {
      set({
        classLimit: limit
      })
    },
    resetScheduler: () => {
      set({
        availableClasses: [],
        selectedClassPks: [],
        selectedClasses: [],
        timezone: getCurrentTimeZoneString(),
        openClass: ``,
        rescheduling: false,
        reschedulingClasses: [],
        selectedDays: [],
        previouslyScheduledClasses: [],
        completedClasses: [],
        classLimit: CLASS_LIMIT
      })
    },
    removeClassSelection: ( classDetails: Class, timeSlot: TimeSlot ) => {
      get().removeSelectedClassPk( timeSlot.class_pk )
      get().setSelectedDays(
        get().selectedDays.filter( ( day: string ) => {
          return day !== timeSlot.scheduled_date
        })
      )
      get().removeSelectedClass( classDetails )
    },
    setOpenClass: ( _classId: string ) => {
      set({
        openClass: _classId
      })
    },
    setRescheduling: ( rescheduling: boolean ) => {
      set({
        rescheduling: rescheduling
      })
    },
    setReschedulingClasses: ( classes: RescheduleClass[] ) => {
      set({
        reschedulingClasses: classes
      })
    },
    setRemoveSeriesClass: ( series_class_pk: number | null ) => {
      set({
        removeSeriesClass: series_class_pk
      })
    }
  }
})

export default useClassSchedulerStore