import { CustomData, useData, useInitialize } from "@kameleoon/react-sdk"
import { CLASSES_SCHEDULED_GOAL_ID, CUSTOM_DATA } from "utils/kameleoon/constants"
import { reportToSentry } from "utils/reportToSentry"

type TrackClassScheduleConversionProps = {
  classList: string;
  classScheduleType: string;
}

type TrackClassScheduleConversionReturn = {
  trackClassScheduleConversion: ( props: TrackClassScheduleConversionProps ) => void
}
export const useTrackConversion = ( visitorCode: string ): TrackClassScheduleConversionReturn => {
  const { isInitialized } = useInitialize()
  const { addData, trackConversion } = useData()

  const trackClassScheduleConversion = ({ classList, classScheduleType }: TrackClassScheduleConversionProps ) => {
    const scheduledClassesData = new CustomData( CUSTOM_DATA.SCHEDULED_CLASSES, classList )
    const classScheduleTypeData = new CustomData( CUSTOM_DATA.CLASS_SCHEDULE_TYPE, classScheduleType )
    try {
      if ( !isInitialized() ) throw new Error( `Kameleoon is not initialized` )
      addData( visitorCode, scheduledClassesData, classScheduleTypeData )
      trackConversion({
        visitorCode,
        goalId: CLASSES_SCHEDULED_GOAL_ID
      })
    } catch ( e ) {
      reportToSentry( `Error adding data to Kameleoon session`, e )
    }
  }

  return {
    trackClassScheduleConversion
  }
}