import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from "@apollo/client/link/error"
import { RetryLink } from '@apollo/client/link/retry'
import { MAINTENANCE_PAGE_PATH } from 'routes'
import useMaintenanceNav from 'hooks/useMaintenanceNav'
import { useNavigate } from 'react-router-dom'
import { getMaintenancePageStatus } from 'components/MaintenanceListener/utils'
import { reportToSentry } from 'utils/reportToSentry'

const useApolloClient = () => {
  const { setPreviousPath } = useMaintenanceNav()
  const navigate = useNavigate()

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/graphql`
  })
  const authLink = setContext( ( _, { headers }) => {
    const token = sessionStorage.getItem( `magentoToken` )

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ``
      }
    }
  })

  const errorLink = onError( ({ graphQLErrors, networkError }) => {
    if ( graphQLErrors ) {
      reportToSentry( new Error( `GraphQL Error` ), {
        graphQLErrors
      })
    }

    if ( networkError ) {
      reportToSentry( new Error( `Magento Network Error` ), {
        networkError
      })
      if ( getMaintenancePageStatus() ) {
        // magento is down and react site is in maintenance mode => send users to maintenance page
        setPreviousPath( window.location.pathname.concat( window.location.search ) )
        navigate( MAINTENANCE_PAGE_PATH )
      }
    }
  })

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 5,
      retryIf: ( error, _ ) => {
        return Boolean( error )
      }
    }
  })

  const apolloClient = new ApolloClient({
    link: from( [ retryLink, errorLink, authLink, httpLink ] ),
    fetchOptions: {
      mode: `cors`
    },
    cache: new InMemoryCache()
  })

  return apolloClient
}

export default useApolloClient


