import { useKameleoonStore } from "stores/kameleoonStore"
import { IndividualSelection } from "views/IndividualSelection"
import { Individual as IndividualClassScheduler } from "components/ClassSchedule/Individual"

const Individual = () => {
  const uxUpdateFeature = useKameleoonStore( store => store.uxUpdateFeature )

  if ( uxUpdateFeature ) {
    return <IndividualClassScheduler />
  }

  return <IndividualSelection />
}

export default Individual