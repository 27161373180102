import { reportToSentry } from 'utils/reportToSentry'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { ClassProduct } from '../types/shopScheduleTypes'

export type CustomerPricingData = {
  patient: {
    address: {
      street: string,
      city: string,
      state: string,
      zip: string
    }
    auto_pk: string
    county: string
    dob: string
    doctor: {
      OutputType: string,
      patient_id: number,
      magento_customer_id: string,
      auto_pk: string,
      doctor_id: number
    }
    email: string
    first_name: string
    generation: null
    height: null
    last_birth_type_provided: string
    last_due_date_provided: string
    last_name: string
    magento_customer_id: string
    messages: string[]
    middle_initial: string
    patient_id: number
    payers: { payer1: string, payer2: string, payer3: string }
    phone: string
    products: ClassProduct[]
    responsible_person_city: string
    responsible_person_name: string
    responsible_person_phone_number: string
    responsible_person_state: string
    responsible_person_street: string
    responsible_person_zip: string
    sex: string
    ssn: string
    weight: string
  }
}

interface PatientDataStore {
  customerPricingData: CustomerPricingData | undefined;
  setCustomerPricingData: ( _authHeader: string ) => Promise<void>;
}

declare global {
  interface Window {
    NOIBUJS: {
      addCustomAttribute: ( _name: string, _value: string ) => void;
    };
  }
}

async function addPatientIdToNoibu( patientId: string ) {
  if ( !window.NOIBUJS ) {
    await new Promise( resolve => {
      window.addEventListener( `noibuSDKReady`, resolve )
    })
  }
  window.NOIBUJS.addCustomAttribute( `patient_id`, patientId )
}

/**
 * Getting product pricing information for a patient. It also returns a lot of patient data.
 *
 * @param {string} authHeader
 * @returns json response which has patient data in it
 * @throws Error if an invalid response is returned
 */
async function fetchProductPricing( authHeader: string ) {
  const response = await fetch( `${process.env.REACT_APP_PATIENTS_URL}/v2/product-pricing?store_name=momandbaby`, {
    headers: {
      'Authorization': authHeader
    }
  })

  if ( response.ok ) {
    return await response.json()
  } else {
    throw new Error( `Error fetching product pricing: ` + response.status + ` ` + response.statusText )
  }
}

export const usePatientDataStore = create<PatientDataStore>()(
  persist( ( set ) => ({
    customerPricingData: undefined,
    setCustomerPricingData: async ( _authHeader: string ) => {
      try {
        const response = await fetchProductPricing( _authHeader )
        set({
          customerPricingData: response?.data ?? {}
        })

        const patientId = response.data?.patient?.patient_id?.toString() ?? `unknown`
        await addPatientIdToNoibu( patientId )

      } catch ( error ) {
        reportToSentry(
          new Error( `Error fetching customer pricing data`, {
            cause: error
          }),
          {
            error: JSON.stringify( error )
          }
        )
      }
    }
  }),
  {
    name: `customer-pricing`,
    storage: createJSONStorage( () => sessionStorage )
  })
)
