import { useKameleoonStore } from "stores/kameleoonStore"
import { Confirm as ConfirmClasses } from "components/ClassSchedule/Confirm"
import NotFound from "views/NotFound"

const Confirm = () => {
  const uxUpdateFeature = useKameleoonStore( store => store.uxUpdateFeature )

  if ( uxUpdateFeature ) {
    return <ConfirmClasses />
  }

  return <NotFound />
}

export default Confirm