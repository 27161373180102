import { BrowserRouter as Router } from 'react-router-dom'
import { RouteMapping } from 'components/Route'
import Layout from 'components/Layout'
import 'styles/tailwind.css'
import { ErrorHandler } from 'components/ErrorBoundary'
import MaintenanceListener from 'components/MaintenanceListener'
import TokenCheck from 'views/TokenCheck/TokenCheck'
import ApolloSetup from 'components/ApolloSetup/ApolloSetup'
import MABTheme from 'config/MABTheme'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import KameleoonSetup from './components/KameleoonSetup'
import { useEffect } from 'react'

const App = () => {
  useEffect( () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: `pageview`,
      siteSection: `Lactation Scheduler`
    })
  }, [] )

  return (
    <ErrorHandler>
      <Router>
        <GoogleReCaptchaProvider reCaptchaKey="6LfsYuYpAAAAADMjInfXaD-7xZ5FGclge88Yo6U4">
          <KameleoonSetup>
            <ApolloSetup>
              <MaintenanceListener />
              <TokenCheck />
              <Layout>
                <MABTheme>
                  <RouteMapping />
                </MABTheme>
              </Layout>
            </ApolloSetup>
          </KameleoonSetup>
        </GoogleReCaptchaProvider>
      </Router>
    </ErrorHandler>
  )
}

export default App
