import { Bundle } from 'types/shopScheduleTypes'
import ReactHtmlParser from 'react-html-parser'
import { DomElement } from "htmlparser2"
import { useState } from 'react'
import { BundleDetailsModal } from 'components/AdditionalDetails'

type BundleCardProps = {
  bundleDetails: Bundle,
  selectBundle: () => void,
  selected: boolean,
  readOnly?: boolean
}
export default function BundleCard({ bundleDetails, selectBundle, selected, readOnly }: BundleCardProps ) {
  const [ modalOpen, setModalOpen ] = useState<boolean>( false )

  const handleClose = () => {
    setModalOpen( false )
  }

  const transform = ( node: DomElement ) => {
    if ( node?.name === `li` ) node.attribs.class = `bullet-point`
  }

  return (
    <div className="flex gap-4">
      {bundleDetails?.bundle_short_description?.length > 0 &&
        <BundleDetailsModal
          bundle={bundleDetails}
          modalOpen={modalOpen}
          handleClose={handleClose}
        />
      }
      <img
        src={bundleDetails?.image_path}
        width={90}
        height={90}
        className="object-cover h-90px"
      />
      <div className="w-full text-left flex flex-col gap-2.5">
        <h3>{bundleDetails?.bundle_title}</h3>
        {bundleDetails?.bundle_short_description?.length > 0 &&
          <a className="font-bold text-sm text-secondary" onClick={( () => { setModalOpen( true ) })}>{`Details`}</a>
        }
        {
          bundleDetails?.bundle_description?.length > 0 &&
          ReactHtmlParser( bundleDetails?.bundle_description, {
            transform
          })
        }
        {!readOnly &&
          <button
            className={`${selected ? `bg-transparent text-secondary` : `bg-secondary text-pink-1`} border border-secondary rounded-button p-button text-sm tracking-widest uppercase w-fit`}
            onClick={selectBundle}
          >{selected ? `Remove` : `Select`}</button>
        }
      </div>
    </div>
  )
}