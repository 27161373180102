import { FormEvent, useEffect, useRef } from "react"
import useClassFiltersStore from "stores/useClassFiltersStore"

type TopicFilterProps = {
    tagButtons: { key: string, selected: boolean, text: string }[]
    closeFilter: () => void
}
const TopicFilter = ({tagButtons, closeFilter}: TopicFilterProps ) => {
  const clearFilteredTopics = useClassFiltersStore( state => state.clearFilteredTopic )
  const addFilteredTopic = useClassFiltersStore( state => state.addFilteredTopic )

  const applyFilters = ( event: FormEvent ) => {
    event.preventDefault()
    clearFilteredTopics()
    tagButtons.forEach( ( tagButton ) => {
      const checkbox = document.getElementById( tagButton.key ) as HTMLInputElement
      if ( checkbox.checked ) addFilteredTopic( tagButton.key )
    })
    closeFilter()
  }

  const topicFilterDropdownRef = useRef<HTMLFormElement>( null )
  useEffect( () => {
    const handleClickOutside = ( event: MouseEvent ) => {
      if ( !topicFilterDropdownRef.current?.contains( event.target as Node ) ) {
        if ( !( event.target instanceof HTMLButtonElement && event.target.name === `topicFilter` ) ) {
          closeFilter()
        }
        document.removeEventListener( `mousedown`, handleClickOutside )
      }
    }
    document.addEventListener( `mousedown`, handleClickOutside )
  }, [] )

  return (
    <form
      onSubmit={applyFilters}
      className="flex flex-col gap-y-2"
      ref={topicFilterDropdownRef}
    >
      {tagButtons.map( ( tagButton ) => {
        return (
          <div
            key={tagButton.key}
            className="flex justify-start gap-2.5"
          >
            <input
              type="checkbox"
              id={tagButton.key}
              name={tagButton.key}
              defaultChecked={tagButton.selected}
              className="w-auto focus:ring-0 my-auto"
            />
            <label htmlFor={tagButton.key}>{tagButton.text}</label>
          </div>
        )
      })}
      <button
        type="submit"
        className="bg-pink-3 rounded-button px-5 py-1.5 w-fit uppercase tracking-widest text-black font-semibold text-sm"
      >
        {`Apply`}
      </button>
    </form>
  )
}

export default TopicFilter