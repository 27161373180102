import { getSource } from "utils/getSource"

const fetchClasses = async ( auth: string ) => {
  const m2AuthUsed = auth.includes( `Bearer` )

  const response = await fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/classes?source=${getSource()}`, {
    headers: {
      ...( ( process.env.REACT_APP_ENVIRONMENT === `development` || process.env.REACT_APP_ENVIRONMENT === `local` ) && m2AuthUsed &&
      {
        'x-aeroflow-magento-environment':`dev`// otherwise the middleware authorizor will look to staging m2 backend for auth
      }
      ),
      "Authorization": auth
    }
  })

  return await response.json()
}

export default fetchClasses