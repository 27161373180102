import { useKameleoonStore } from "stores/kameleoonStore"
import NotFound from "views/NotFound"
import { ShopScheduleThankYou } from "views/ThankYou"

const ThankYou = () => {
  const uxUpdateFeature = useKameleoonStore( store => store.uxUpdateFeature )

  if ( uxUpdateFeature ) {
    return <ShopScheduleThankYou />
  }

  return <NotFound />
}

export default ThankYou