import Scheduler from "./Scheduler"
import { Class, ClassDescription } from 'types/shopScheduleTypes'
import { useState } from "react"
import useClassScheduler from "stores/useClassSchedulerStore"
import { ClassDetailsModal } from "components/AdditionalDetails"

type ClassCardProps = {
  classDetails: Class
}

export default function ClassCard({ classDetails }: ClassCardProps ) : JSX.Element {
  const availableClasses = useClassScheduler( state => state.availableClasses )
  const [ modalOpen, setModalOpen ] = useState<boolean>( false )
  const classDuration = availableClasses.find( ( c: ClassDescription ) => c.class_id === classDetails.class_id )?.timeslots?.[0]?.duration_minutes

  const handleClose = () => {
    setModalOpen( false )
  }

  return (
    <div className="flex flex-col gap-4">
      <ClassDetailsModal
        classDetails={classDetails}
        modalOpen={modalOpen}
        handleClose={handleClose}
      />
      <div className="flex gap-4">
        <div>
          <img
            src={classDetails?.image_path}
            width={90}
            height="auto"
            className="mt-2 object-fill"
          />
        </div>
        <div className="w-full text-left flex flex-col gap-1">
          <h3>{classDetails.class_title}</h3>
          <p className="font-normal text-xs">{classDuration && `Class Length: ${classDuration} mins`}</p>
          <a className="font-bold text-sm text-secondary" onClick={( () => { setModalOpen( true ) })}>{`Details`}</a>
        </div>
      </div>
      <Scheduler classDetails={classDetails} />
    </div>
  )
}